import React from 'react';
import FormButton from "../../components/formik/elements/Button";
import {useHistory} from "react-router-dom";
import FormHeading from "../../components/formik/elements/FormHeading";

export default () => {

    let history = useHistory();

    return (
        <div className="container-fluid justify-content-center w-100 col-xl-7">
            <FormHeading text="404 - Puslapis nerastas"/>
            <p>Puslapio kurio ieškote - nepavyko rasti.</p>
            <FormButton text="Grįžti į pradžią" onClick={() => history.push(`/`)}/>
        </div>
    );
}