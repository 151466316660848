import {getTitleByScale} from "../../../api/utils"
import {useEffect, useState} from "react";
import {diagnosticsToCategory} from "./categories/utils";

const DiagnosticEstimatesHeader = ({surveyDiagnostics}) => {

    const [scales, setScales] = useState([]);

    useEffect(()=>{
        const scales = surveyDiagnostics?.detailedEstimates.reverse();
        setScales(scales)
    },[])

    const category = diagnosticsToCategory(surveyDiagnostics)

    const style = {
        padding: `15px`,
        backgroundColor: category.color,
        color: category.grade < 4 ? "black" : "white",
    }

    const renderScale = (scaleDetails) => {
        return (
            <div key={scaleDetails.scale} className="w-50 text-center">
                <span>
                    <strong>{getTitleByScale(scaleDetails.scale)}:</strong>
                    &nbsp;
                    {`${Math.round(scaleDetails.scaleTotalEstimate)}/100`}
                </span>
            </div>
        )
    }

    return (
        <div style={style} className="d-flex justify-content-between">
            {scales?.map(renderScale)}
        </div>
        )
}

export default DiagnosticEstimatesHeader;