const QuestionnaireHeading = ({prefix, text}) => {
    return (
        <p className="questionnaire__heading">
            <strong>
                {prefix}
            </strong>
            &nbsp;
            {text}
        </p>
        )
}

export default QuestionnaireHeading;