import React, {useEffect, useState} from "react";
import {Alert, Spinner, Table, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import recommendationApi from "../../api/recommendationApi";
import FormButton from "../../components/formik/elements/Button";
import FormHeading from "../../components/formik/elements/FormHeading";
import ratingApi from "../../api/ratingApi";

export default () => {
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () =>
        recommendationApi
            .fetchRecommendations()
            .then((response) => setRecommendations(response.data))
            .finally(() => setIsLoading(false));

    const onDelete = (id) => {
        recommendationApi
            .deleteRecommendation(id)
            .then((response) => {
                fetchData();
                setError(null)
            })
            .catch((err) => {
                setError("Vertinimas negali būti ištrintas nes yra naudojamas kituose klausimynuose.");
            });
    };

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Rekomendacijos"/>
            {error && <Alert variant="danger">{error}</Alert>}
            <Link to="/cms/recommendations/new">
                <FormButton text="Kurti naują vertinimą"/>
            </Link>
            {isLoading && (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )}
            {recommendations.length > 0 && (
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>Nuoroda</th>
                        <th>Pavadinimas</th>
                        <th>Aprašymas</th>
                        <th colSpan={2}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {recommendations.map((t) => (
                        <tr key={t.id}>
                            <td >
                                <a className="form__link ml-1" target="_blank" rel="noreferrer" href={t.link}>
                                    Link
                                </a>
                            </td>
                            <td>{t.title}</td>
                            <td className="w-100">{t.message}</td>
                            <td>
                                <Link to={`/cms/recommendations/${t.id}/edit`}>
                                    <FormButton isForTable text="Redaguoti"/>
                                </Link>
                            </td>
                            <td>
                                <FormButton isForTable text="Ištrinti" onClick={() => onDelete(t.id)}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
