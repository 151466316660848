import React from 'react';

export default ({formik, questionOptions, index}) => {

    const elementName = `answers[${index}].id`;

    // sets formik field with initial value
    // to match initial slider position
    if (!formik.values.answers[index]) {
        formik.values.answers[index] = {
            id: questionOptions[0].id,
            value: questionOptions[0].min
        }
    }

    return (
        <>
            <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-column align-items-end justify-content-center">
                    <span className="mr-3 text-right">{questionOptions[0].minTitle}</span>
                </div>
                <input type="range"
                       className="w-100"
                       name={elementName}
                       min={questionOptions[0].min}
                       max={questionOptions[0].max}
                       step={questionOptions[0].step}
                       id={questionOptions[0].id}
                       value={formik.values.answers[index]?.value || questionOptions[0].min}
                       onChange={e => {
                           let answers = formik.values.answers
                           answers[index] = {
                               id: questionOptions[0].id,
                               value: e.target.value
                           };
                           formik.setValues({
                               ...formik.values,
                               answers
                           });
                       }
                       }
                       onBlur={formik.handleBlur}
                />
                <div className="d-flex flex-column justify-content-center">
                    <span className="ml-3">{questionOptions[0].maxTitle}</span>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <span>Pasirinkta reikšmė:</span>
                <strong className="mr-1 ml-1">{formik.values.answers[index]?.value}</strong>
                <span>{questionOptions[0].units}</span>
            </div>
        </>
    );
}