import {Col, Form, Row} from "react-bootstrap";
import ConjunctionSelect from "./ConjunctionSelect";
import NegateCheckbox from "./NegateCheckbox";
import React, {useState} from "react";

const ObservedSingleSelect = ({
                                  currentCondition,
                                  allOptionsOfObservedQuestion,
                                  formik,
                                  requirementIndex,
                                  conditionIndex
                              }) => {

    const condition =  formik.values.questionShows[requirementIndex].questionShowConditions[conditionIndex];
    const observedOptions = condition.questionOptions;
    const conjunction = condition.conditionType;
    const notCondition = condition.notCondition

    const conditionFieldName = `questionShows[${requirementIndex}].questionShowConditions[${conditionIndex}]`
    const observedOptionsFieldName = `${conditionFieldName}.questionOptions`
    const conjunctionFieldName = `${conditionFieldName}.conditionType`
    const notConditionFieldName = `${conditionFieldName}.notCondition`

    const observedOptionsIdList = observedOptions.map(option => option.id);
    const [selectedValue, setSelectedValue] = useState(observedOptionsIdList.find(option => option !== null && option !== undefined));

    const handleChange = (e, optionIndex) => {
        let currentChoice = [...observedOptions]
        currentChoice.length = 0;
        currentChoice[optionIndex] = {id: parseInt(e.target.value)};
        formik.setFieldValue(observedOptionsFieldName, currentChoice)
        setSelectedValue(parseInt(e.target.value))
    }

    return (
        <div key={`${requirementIndex}cond${conditionIndex}`}>
            <Row>
                <Col xs="auto" className="d-flex mt-3 mb-1">
                    {conditionIndex > 0 &&
                    // do not show conjunction select for first condition as it has nothing to be compared against
                    <div>
                        <ConjunctionSelect formik={formik} name={conjunctionFieldName} value={conjunction}/>
                    </div>
                    }
                    <div className="m-1 ml-3">
                        {notCondition
                            ? <span>Buvo <span style={{color:"blue"}}>ne</span>pasirinktas variantas: </span>
                            : <span>Buvo pasirinktas variantas: </span>
                        }
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">

                <Col xs="auto" className="d-flex align-items-center">
                    <NegateCheckbox formik={formik} name={notConditionFieldName} value={notCondition} condition={currentCondition}/>
                </Col>
                <Col>
                    {
                        allOptionsOfObservedQuestion.map((option, optionIndex) => {
                                return (
                                    <>
                                    <div key={requirementIndex + conditionIndex + "" + optionIndex}>
                                        <Form.Check type="radio"
                                                    inline
                                                    name={`${conditionFieldName}.questionOptions[${optionIndex}]`}
                                                    value={option.id}
                                                    onChange={(e)=>handleChange(e, optionIndex)}
                                                    onBlur={formik.handleBlur}
                                                    checked={selectedValue===option.id}
                                        />
                                        {option.value}
                                    </div>
                                    </>
                                )
                            }
                        )
                    }
                </Col>
            </Row>
            <hr/>
        </div>
    )
}

export default ObservedSingleSelect;