import React from "react";
import cx from "classnames"

const DiagnosticsHeading = ({text}) => {
    return (
        <div className={cx({
            "diagnostics__heading": true,
            "mb-3": true,
        })}>
            <h1>{text}</h1>
        </div>
    )
}

export default DiagnosticsHeading;