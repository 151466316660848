const HowItWorks = () => {
    return (
        <div className="how-it-works p-4 pl-5 pr-5 mt-3">
            <h4 className="text-center m-3">
                KAIP VYKSTA EKSPORTO PATIRTIES IR PROCESŲ ANALIZĖ?
            </h4>
            <p className="text-center m-2 mb-4">
                Eksporto diagnostikos teste pagal jūsų atsakymus vertinama įmonės eksporto patirtis ir pasirengimas tolimesniam augimui. Temos apima visus eksporto procesus nuo rinkodaros iki pardavimų valdymo. Metodika parengta remiantis ilgalaike verslo konsultavimo praktika.
            </p>
        </div>
        )
}

export default HowItWorks;