import {Modal} from "react-bootstrap";
import FormButton from "../../../components/formik/elements/Button";

const ConfirmConfirm = ({showConfirmationModal, handleClose, handleConfirm, companyToConfirm, isLoading}) => {
    return (
        <Modal className="modal" show={showConfirmationModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm the action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <strong className="mb-3">Are you sure you want to confirm the company <span
                        style={{color: "var(--dark-blue"}}>{companyToConfirm.companyName}</span>?</strong>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <FormButton text="Cancel" onClick={handleClose} isSwitched mr/>
                <FormButton text={isLoading ? "Loading..." : "Confirm"} onClick={handleConfirm}
                            disabled={isLoading}/>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmConfirm