import HTTP from './index';

export const fetchAttendees = () => HTTP.get("/attendee");
export const fetchAttendeesPage = (page, size) => HTTP.get(`/attendee/paged?page=${page}&size=${size}&sort=id,DESC`);
export const registerAttendee = (attendee) => HTTP.post("/attendee", attendee);
export const deleteAttendee = (id) => HTTP.delete(`/attendee/${id}`);
export const inviteAttendee = (uuid) => HTTP.post(`/attendee/invite/${uuid}`);
export const confirmAttendee = (uuid) => HTTP.put(`/attendee/confirmation/${uuid}`);

export const fetchAttendee = (uuid) => HTTP.get(`/attendee/public/${uuid}`);
