import FormButton from "../../../components/formik/elements/Button";
import newWindow from "../img/new-window-icon3.png";
import React from "react";
import StatusTag from "./StatusTag";
import moment from "moment"
import 'moment/locale/lt';


const hasError = (row) => {
    // may have some invalid entries from old testing leftovers
    const isFinishedWithoutName = row.status === "FINISHED" && !row.attendeeName;
    const isWithoutAttendeeCode = !row.attendeeCode;
    return isFinishedWithoutName || isWithoutAttendeeCode
}

export const getCompaniesTableColumns = (sendInvite, handleDelete, handleConfirm) => [
    {
        dataField: 'id',
        text: 'ID'
    }, {
        dataField: 'companyName',
        text: 'Įmonės pavadinimas',
    }, {
        dataField: 'companyWebsite',
        text: 'Įmonės tinklalapis',
        formatter: (cellContent, row) => <a href={row.companyWebsite} target="_blank" rel="noreferrer">{row.companyWebsite}</a>
    }, {
        dataField: 'attendeeName',
        text: 'Dalyvio vardas'
    }, {
        dataField: 'email',
        text: 'El. paštas',
        formatter: (cellContent, row) => <a href={`mailto:${row.email}`}>{row.email}</a>
    }, {
        dataField: 'targetCountry',
        text: 'Tikslinė rinka'
    }, {
        dataField: 'phoneNo',
        text: 'Tel. Nr.'
    }, {
        dataField: 'isExport',
        text: 'Export',
        formatter: (cellContent, row) => (row.isExport === true ? 'Yes' : row.isExport === false ? 'No' : '' )
    }, {
        dataField: 'inviteCount',
        isDummyField: true,
        text: 'Pakvietimas',
        formatter: (cellContent, row) => {
            const hasFinished = row.status === "FINISHED"
            return (
                row.inviteCount > 0
                    ? <FormButton
                        isForTable
                        disabled={hasFinished || !row.isConfirm}
                        isSwitched
                        text={`Kartoti pakvietimą`}
                        onClick={() => sendInvite(row)}
                    />
                    : <FormButton
                        isForTable
                        disabled={hasFinished || !row.isConfirm}
                        text="Siųsti pakvietimą"
                        onClick={() => sendInvite(row)}/>
            )
        }
    }, {
        dataField: 'status',
        text: 'Būsena',
        isDummyField: true,
        formatter: (cellContent, row) => <StatusTag status={row.status} attendeeCode={row.attendeeCode} hasError={hasError(row)}/>
    }, {
        dataField: 'attendeeCode',
        text: 'Detali apžvalga',
        formatter: (cellContent, row) => {
            return (
                row.status === "FINISHED" && !hasError(row) ?
                        <a href={`/cms/apklausa/${row.attendeeCode}/ivertinimas/detalus`}
                           target="_blank" rel="noreferrer">
                            detaliau <img src={newWindow} alt={"new window icon"}/>
                        </a>
                    : <span>-</span>

            )
        }
    },
    {
        dataField: 'surveyFinishedAt',
        text: 'Baigta:',
        formatter: (cellContent, row) => {
            const hasDate = !!row.surveyFinishedAt;
            moment.locale("lt")
            const date = moment(row.surveyFinishedAt)
            return <span>{hasDate ? date.format('L') : '-'} {hasDate && date.format('LT')}</span>
        }
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Veiksmai',
        headerStyle: (colum, colIndex) => {
            return { width: '180px' };
        },
        formatter: (cellContent, row) => {
            return <>
                {!row.isConfirm ? <FormButton isForTable text="Confirm" onClick={() => handleConfirm(row)}/> : ''}
                <FormButton isForTable text="Šalinti" onClick={() => handleDelete(row)}/>
            </>
        }
    },

];