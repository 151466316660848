import React from "react";
import "../../../styles/buttons.css"
import cx from "classnames"

export default ({text, onClick, disabled, type, id, isSmall, isForTable, isSwitched, mr, isForLgScreen}) => (
    <button type={type}
            onClick={onClick}
            disabled={disabled}
            className={cx({
                "form__button":true,
                "form__button--small": isSmall,
                "form__button--table": isForTable,
                "form__button--switched": isSwitched,
                "form__button--lg-only": isForLgScreen,
                "mr-2": mr,
            })}
            key={id}
    >
        {text}
    </button>
)