const CriteriaCard = ({criteria: {icon, title, body}}) => {
    return (
        <div className="criteria-card col m-2 p-2">
                <div className="d-flex align-items-center mt-2">
                    <img className="m-1" src={icon} alt="Branda"/>
                    <strong>{title}</strong>
                </div>
                <div className="mt-4 p-2">
                    <span>{body}</span>
                </div>
        </div>
    )
}

export default CriteriaCard;