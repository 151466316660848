import CategoryDescription from "./CategoryDescription";
import React from "react";
import {diagnosticsToCategory} from "./categories/utils";
import Diagram from "./Diagram";

const DiagnosticGraphicalResult = ({surveyDiagnostics, sectorAverage}) => {
    const category = diagnosticsToCategory(surveyDiagnostics)
    return (
        <div className="d-flex justify-content-between mb-2 flex-wrap">
            <CategoryDescription category={category}/>
            <div className="w-50 d-flex justify-content-center">
                <Diagram
                    category={category}
                    sectorAverage={sectorAverage}
                    surveyDiagnostics={surveyDiagnostics}/>
            </div>
        </div>
    )
}

export default DiagnosticGraphicalResult;