import Recommendation from "./Recommendation";
import DiagnosticsHeading from "./DiagnosticsHeading";
import React, {useEffect, useState} from "react";
import recommendationApi from "../../../api/recommendationApi";

const DiagnosticRecommendationsCommon = () => {
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () =>
        recommendationApi
            .fetchRecommendations()
            .then((response) => setRecommendations(response.data))
            .finally(() => setIsLoading(false));
    return (
        <div className="pt-3">
            <DiagnosticsHeading text="KITOS REKOMENDUOJAMOS EKSPORTO PAGALBOS PRIEMONĖS"/>
            {recommendations.map(({title, message, link, id}) => {
                return (
                    <Recommendation title={title} message={message} link={link} id={id} key={id}/>
                )
            })}
        </div>
    )
}

export default DiagnosticRecommendationsCommon;