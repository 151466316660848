import FormButton from "../../components/formik/elements/Button";
import CriteriaCard from "./CriteriaCard";
import {criteria} from "./properties/landingPageProperties";

const Features = ({onStart}) => {
    return (
        <div className="features d-flex flex-column align-items-center mt-4 mb-2">
            <FormButton isSwitched text="Pradėti testą" onClick={onStart}/>
            <h1 className="mt-5 mb-4 text-center">KOKIE EKSPORTO PROCESAI VERTINAMI?</h1>
            <p className="text-center">Eksporto diagnostikos testas analizuoja šešis pagrindinius eksporto procesus,
                kurie lemia plėtros į užsienio rinkas sėkmę.</p>
            <div className="d-flex flex-wrap justify-content-center p-2 mt-3">
                <CriteriaCard criteria={criteria.BASIC_READINESS}/>
                <CriteriaCard criteria={criteria.MARKET_KNOWLEDGE}/>
                <CriteriaCard criteria={criteria.PRODUCT_READINESS}/>
                <CriteriaCard criteria={criteria.MARKETING}/>
                <CriteriaCard criteria={criteria.SALES_MANAGEMENT}/>
                <CriteriaCard criteria={criteria.WILL_TO_EXPAND}/>
            </div>
        </div>
    )
}

export default Features;