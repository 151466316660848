import React from 'react';
import {ErrorMessage} from 'formik';

export default ({formik, questionOptions, index}) => {

    const elementName = `answers[${index}].id`;

    const isChecked = (id) => {
        return Array.isArray(formik.values.answers[index]?.id) &&
            formik.values.answers[index]?.id.includes(id.toString())
    }

    return (
        <div>
            {questionOptions.map((o, oIndex) => (
                <div key={"opt-div" + o.id}>
                    <label className="checkbox" key={"opt" + o.id}>
                        <span className="checkbox__input">
                            <input
                                type="checkbox"
                                name={elementName}
                                value={questionOptions[oIndex].id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultChecked={isChecked(o.id)}
                            />
                    <span className="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                 focusable="false">
                                <path fill='none' stroke='currentColor' strokeWidth='3'
                                      d='M1.73 12.91l6.37 6.37L22.79 4.59'/>
                            </svg>
                        </span>
                        </span>
                        <span className="control__label">{o.value}</span>
                    </label>
                    <ErrorMessage name={elementName} component="div" className="invalid-feedback"/>
                </div>
            ))}
        </div>
    );
}