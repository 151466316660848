import React, {useContext} from 'react';
import AnswerMultiselect from './AnswerMultiselect';
import AnswerRadio from './AnswerRadio';
import AnswerInput from './AnswerInput';
import AnswerDropdown from './AnswerDropdown';
import AnswerSlider from './AnswerSlider';
import QuestionHeading from "../../components/formik/elements/QuestionHeading";
import {TargetCountryContext} from "../../App";

export default ({formik, question, index, saveQuestionToHistory}) => {

    const {targetCountry} = useContext(TargetCountryContext);

    function renderAnswerControl() {
        saveQuestionToHistory();
        switch (question.type) {
            case 'RADIO':
                return (<AnswerRadio formik={formik} questionOptions={question.questionOptions} index={index}/>);
            case 'DROPDOWN':
                return (<AnswerDropdown formik={formik} questionOptions={question.questionOptions} index={index}/>);
            case 'MULTI_SELECT':
                return (<AnswerMultiselect formik={formik} questionOptions={question.questionOptions} index={index}/>);
            case 'INPUT':
                return (<AnswerInput formik={formik} questionOptions={question.questionOptions} index={index}/>);
            case 'SLIDER':
                return (<AnswerSlider formik={formik} questionOptions={question.questionOptions} index={index}/>);
            default:
                return (<span>Daugiau klausimų neturime.</span>);
        }
    }

    return (
        <div key={index}>
            <QuestionHeading text={index - 1 + '. ' + question.name} hint={question.description}/>
            {question.hasTargetCountry &&
            <p className="country-label">Jūsų pasirinkta tikslinė
                rinka: <strong>{targetCountry.label} ({targetCountry.value})</strong></p>
            }
            {renderAnswerControl()}
            <br/>
        </div>
    );
}