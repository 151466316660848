import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/colors.css";
import "./styles/main.css";
import "./styles/fonts.css";
import "./styles/form-control.css";
import "./styles/progressBar.css";
import "./styles/diagnostics.css";
import "./styles/diagram.css";
import "react-step-progress-bar/styles.css";
import "./styles/landing.css"
import "./styles/companies.css"
import App from "./App";

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
