import {Modal} from "react-bootstrap";
import FormButton from "../../../components/formik/elements/Button";

const DeleteConfirm = ({showConfirmationModal, handleClose, handleConfirm, companyToDelete, isLoading}) => {
    return (
        <Modal className="modal" show={showConfirmationModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Patvirtinkite veiksmą</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <strong className="mb-3">Ar tikrai norite pašalinti įmonę <span
                        style={{color: "var(--dark-blue"}}>{companyToDelete.companyName}</span>?</strong>
                    {companyToDelete.status === "FINISHED"
                    && <span>Įmonė jau atliko apklausą. Šiuo veiksmu taip pat pašalinsite įmonės apklausos rezultatus, o nuoroda taps neveiksni.</span>
                    }
                    {companyToDelete.status === "REGISTERED" && companyToDelete.inviteCount === 0 &&
                    <span>Įmonei pakvietimas dar nebuvo išsiųstas.</span>}
                    {companyToDelete.status === "REGISTERED" && companyToDelete.inviteCount > 0 &&
                    <span>Įmonei jau buvo <u>išsiųstas pakvietimas</u> dalyvauti.</span>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <FormButton text="Atšaukti" onClick={handleClose} isSwitched mr/>
                <FormButton text={isLoading ? "Vykdoma..." : "Patvirtinti"} onClick={handleConfirm}
                            disabled={isLoading}/>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirm