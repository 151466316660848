import {Form} from "react-bootstrap";
import React from "react";

const NegateCheckbox = ({formik, condition, isForMulti, name, value}) => {
    return (
        <Form.Check type="checkbox"
                    label={isForMulti ? "Nebuvo" : "Ne"}
                    name={name}
                    value={value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultChecked={condition.notCondition}
        />
    )
}

export default NegateCheckbox;