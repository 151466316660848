import React from "react";

const PrintButton = () => {
    return (
        <>
            <p className="mt-3 text-right print-button">
                <a href="" onClick={() => window.print()}> &#128438; Spausdinti rezultatus
                </a>
            </p>
        </>
        )
}

export default PrintButton;