import React, {useState} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Menu from "./components/Menu/Menu";
import QuestionnaireList from "./pages/questionnaire/QuestionnaireList";
import QuestionForm from "./pages/questionnaire/QuestionForm";
import RatingsOverview from "./pages/ratings/RatingsOverview";
import RatingForm from "./pages/ratings/RatingForm";
import QuestionnaireOverview from "./pages/questionnaire/QuestionnaireOverview";
import SurveyAttempt from "./pages/survey/SurveyAttempt";
import SurveyOverview from "./pages/survey/SurveyOverview";
import SurveyAttemptFinish from "./pages/survey/SurveyAttemptFinish";
import QuestionnaireForm from "./pages/questionnaire/createQuestionnaire/QuestionnaireForm";
import NotFoundPage from "./pages/error/NotFoundPage";
import Footer from "./components/Footer/Footer";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginPage from "./pages/login/LoginPage";
import CompaniesOverview from "./pages/companies/CompaniesOverview";
import StatisticsAverage from "./pages/statistics/StatisticsAverage";
import Recommendations from "./pages/recommendations/Recommendations";
import RecommendationForm from "./pages/recommendations/RecommendationForm";
import QuestionsAll from "./pages/questionsAll/QuestionsAll"
import CompanyForm from "./pages/companies/CompanyForm";
import SurveyAttemptAnonymous from "./pages/survey/SurveyAttemptAnonymous";
import PublicAttendeeRegisterForm from "./pages/publicAttendeeRegister/PublicAttendeeRegisterForm";

export const TargetCountryContext = React.createContext({})

const App = () => {

    const [targetCountry, setTargetCountry] = useState('')

    const targetCountryContextState = {
        targetCountry,
        setTargetCountry: (country) => setTargetCountry(country)
    }

    return (
        <React.StrictMode>
                <TargetCountryContext.Provider value={targetCountryContextState}>
                    <BrowserRouter>
                        <Menu/>
                        <div className="content">
                        <Switch>
                            <Route exact path={"/"}>
                                <Redirect to="/apklausa"/>
                            </Route>
                            <Route exact path={"/public-attendee"} render={(props) => <PublicAttendeeRegisterForm {...props} />}></Route>
                            <Route exact path={"/apklausa/:attendee"} render={(props) => <SurveyAttempt {...props} surveyId="1"/>}/>
                            <Route exact path={"/apklausa/:attendee/ivertinimas"} component={SurveyAttemptFinish}/>
                            <PrivateRoute exact path={"/cms/apklausa/:attendee/ivertinimas/detalus"} render={(props) => <SurveyAttemptFinish {...props} isDetail/>} roles={['ROLE_ADMIN']}/>
                            <Route exact path={"/apklausa"} component={SurveyAttemptAnonymous}/>
                            {/*<Route exact path={"/survey/:surveyId/attempt/new"} component={SurveyAttempt}/>*/}
                            {/*<Route exact path={"/survey/:surveyId/attempt/finish/:uuid"} component={SurveyAttemptFinish}/>*/}
                            {/*<Route exact path={"/survey/:surveyId/attempt/finish/:attendee"} component={SurveyAttemptFinish}/>*/}
                            <Route exact path={"/login"} component={LoginPage}/>
                            <PrivateRoute exact path={"/cms/survey/:id"} component={QuestionnaireList} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/survey/:surveyId/questionnaire/:questionnaireId/question/new"} component={QuestionForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/survey/:surveyId/questionnaire/:questionnaireId/question/:questionId/edit"} component={QuestionForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/rating"} component={RatingsOverview} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/rating/new"} component={RatingForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/rating/:ratingId/edit"} component={RatingForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/survey/:surveyId/questionnaire/:questionnaireId"} component={QuestionnaireOverview} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms"} component={SurveyOverview} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/questionnaire/new"} component={QuestionnaireForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/questionnaire/:questionnaireId/edit"} component={QuestionnaireForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/companies"} component={CompaniesOverview} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/companies/new"} component={CompanyForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/statistics"} component={StatisticsAverage} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/questions-all"} component={QuestionsAll} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/recommendations"} component={Recommendations} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/recommendations/new"} component={RecommendationForm} roles={['ROLE_ADMIN']}/>
                            <PrivateRoute exact path={"/cms/recommendations/:recommendationId/edit"} component={RecommendationForm} roles={['ROLE_ADMIN']}/>
                            <Route path="*" component={NotFoundPage}/>
                        </Switch>
                        </div>
                        <Footer/>
                    </BrowserRouter>
                </TargetCountryContext.Provider>
        </React.StrictMode>
    )
}

export default App;