import React from "react"
import '../../../styles/listQuestions.css'

const ListQuestions = ({ data, calcPercent }) => (
  <div className="list-group-all">
    {data?.map(item => (
      <div className='list-item'>
        <div className='list-title'>{item?.questionName}</div>
        <div className='list-count'>Iš viso respondentų: <span className='count'>{item?.count}</span></div>
        <div>
          {item?.answerStatistic?.map(i => (
            <div className='list-box'>
              <div className='item-title'>{i?.answerValue}:</div>
              <div className='item-box'>
                <div className='item-count'>{calcPercent(+item?.count, +i?.answerCount)};</div>
                <div>Respondentų kiekis: <spa className='count-text'>{+i?.answerCount}.</spa></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default ListQuestions
