import React from 'react';
import {Container, Row} from 'react-bootstrap';

import QuestionShowConditions from './QuestionShowConditions';
import ConjunctionSelect from "./ConjunctionSelect";
import FormButton from "../../../components/formik/elements/Button";

const QuestionShow = ({condition, formik, index}) => {

    const handleDeleteCondition = () => {
        const previousConditions = [...formik.values.questionShows]
        const updatedConditions = previousConditions.filter(cond => cond.id !== condition.id);
        formik.setFieldValue("questionShows", updatedConditions)
    }

    return (
        <div>
            {index > 0 &&
            // do not show conjunction select for first condition as it has nothing to be compared against
            <Row className="d-flex flex-column align-items-center mt-3">
                <div>
                    <ConjunctionSelect
                        formik={formik}
                        name={`questionShows[${index}].conditionType`}
                        value={formik.values.questionShows[index].conditionType}
                    />
                </div>
            </Row>
            }
            <div className="p-2 bg-light mt-3">
                <Container>
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <p><strong>{index + 1}.
                                Sąlyga. </strong>Klausime: <strong><i>"{condition.name}"</i></strong></p>
                        </div>
                        <div>
                            <FormButton text="Pašalinti" isForTable onClick={handleDeleteCondition}/>
                        </div>
                    </div>
                    <Row>
                        <QuestionShowConditions condition={condition} formik={formik} topIndex={index}/>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default QuestionShow;