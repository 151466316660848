import React, {useEffect, useState} from "react";
import {Formik, ErrorMessage} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";

import {useHistory} from "react-router-dom";
import Field from "../../components/formik/elements/Field";
import questionApi from "../../api/questionApi";
import QuestionRadio from "./QuestionRadio";
import QuestionSlider from "./QuestionSlider";
import QuestionMultiselect from "./QuestionMultiselect";
import QuestionDropdown from "./QuestionDropdown";
import QuestionInput from "./QuestionInput";
import FormButton from "../../components/formik/elements/Button";
import FormHeading from "../../components/formik/elements/FormHeading";
import QuestionShows from "./questionShow/QuestionShows";
import formDropdownStyle from "../../styles/formDropdownStyle";
import {
    changeObjectNullFieldsToUndefined,
    recreateQuestionShowsCheckboxIndexes, stringifyIntervalNumbers
} from "../../api/utils";

const QuestionForm = ({match: {params: {surveyId, questionnaireId, questionId}}}) => {

    const isEditQuestion = !!questionId;

  let history = useHistory();
  const [questionFields, setQuestionFields] = useState({
    rating: {},
    type: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRating, setSelectedRating] = useState({});
  const [question, setQuestion] = useState({});
  const [questionLoadError, setQuestionLoadError] = useState("");

  useEffect(() => {
    questionApi
      .fetchQuestionFields()
      .then(response => {
        response.data.ratings.unshift({});
        setQuestionFields(response.data)
      })
      .finally(() => setIsLoading(false));

        isEditQuestion &&
        questionApi.fetchQuestionForEdit(surveyId, questionnaireId, questionId)
            .then(response => {
                changeObjectNullFieldsToUndefined(response.data.questionOptions)
                stringifyIntervalNumbers(response.data.questionOptions)
                recreateQuestionShowsCheckboxIndexes(response.data.questionShows)
                setQuestion(response.data)
                setSelectedRating(response.data.rating);
            }).catch(err => {
            if (err.response.status === 404) {
                setQuestionLoadError(err.response.data.error)
            }
        })
    }, []);

  const onRatingSelect = (value) => {
    setSelectedRating(value);
  };

    const returnToQuestionnaire = () => {
        history.push(
            `/cms/survey/${surveyId}/questionnaire/${questionnaireId}`
        );
    }

    const handleFormSubmit = (values) => {
        if (isEditQuestion) {
            questionApi
                .updateQuestion(values, surveyId, questionnaireId, questionId)
                .then(res => returnToQuestionnaire())
        } else {
            questionApi
                .addQuestion(values, surveyId, questionnaireId)
                .then((ee) => {
                    returnToQuestionnaire();
                })
        }
  };

  const initialState = {
        name: isEditQuestion ? question.name : "",
        hasTargetCountry: isEditQuestion ? question.hasTargetCountry : false,
        description: isEditQuestion ? question.description : "",
        rating: isEditQuestion ? question.rating : {},
        type: isEditQuestion ? question.type : "",
        conditionalShow: isEditQuestion ? question.conditionalShow : false,
        isAlwaysCalculated: isEditQuestion ? question.isAlwaysCalculated: false,
        questionOptions: isEditQuestion
            ? question.questionOptions
            : [
                {
                    value: "",
                    weight: null,
                    hasRecommendations: false,
                    minValue: undefined,
                    recommendations: [],
                    min: undefined,
                    max: undefined,
                    step: undefined,
                    minTitle: undefined,
                    maxTitle: undefined,
                },
            ],
        questionShows: isEditQuestion ? question.questionShows : [],
    };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Klausimas negali būti tuščias!"),
    type: Yup.string().required("Atsakymo tipas negali būti tuščias!"),
    questionOptions: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().label("Antraštė negali būti tuščia!"),
        weight: Yup.string().label("Svoris negali būti tuščias!"),
        min: Yup.string().label("Minimali reikšmė negali būti tuščia!"),
        max: Yup.string().label("Maksimali reikšmė negali būti tuščia!"),
        step: Yup.string().label("Žingsnis negali būti tuščias!"),
        minTitle: Yup.string().label("Min antraštė negali būti tuščia!"),
        maxTitle: Yup.string().label("Max antraštė negali būti tuščia!"),
                recommendations: Yup.array().of(
                    Yup.object().shape({
                        triggerCondition: Yup.string().label("Būtina pilnai nurodyti rekomendaciją iššaukiančią sąlygą"),
                        message: Yup.string().label("Rekomendacijos tekstas negali būti tuščias"),
                    }))
      })
    ),
  });

  function renderQuestionOptions(type, formik) {
    switch (type) {
      case "RADIO":
        return <QuestionRadio formik={formik} />;
      case "DROPDOWN":
        return <QuestionDropdown formik={formik} />;
      case "MULTI_SELECT":
        return <QuestionMultiselect formik={formik} />;
      case "INPUT":
        return <QuestionInput formik={formik} />;
      case "SLIDER":
        return <QuestionSlider formik={formik} />;
      default:
        return <span></span>;
    }
  }

  return (
    <div className="container-fluid col-xl-7">
      <FormHeading text={isEditQuestion ? "Klausimo redagavimas" : "Naujas klausimas"}/>
      {questionLoadError && <p className="form__validation-error">{questionLoadError}</p>}
      {!isLoading && !questionLoadError && (
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
           enableReinitialize={isEditQuestion}
          onSubmit={(values) => {
            handleFormSubmit({...values, rating: selectedRating});
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Field
                  label="Klausimas"
                  type={"text"}
                  name={"name"}
                  formik={formik}
                />
                  <Form.Group controlId="targetCountryControlGroup">
                      <Form.Check
                          className="mt-3"
                          label="Klausime rodyti tikslinę rinką"
                          type="checkbox"
                          name="hasTargetCountry"
                          value={formik.values.hasTargetCountry}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.hasTargetCountry}
                      />
                  </Form.Group>
                <Form.Label className="mt-3">Paaiškinimas</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isInvalid={!!formik.errors.description && formik.getFieldMeta("description").touched}
                  className="form__textarea"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="invalid-feedback"
                />
                <Form.Label className="mt-3">Vertinimas (iš sukurtų)</Form.Label>

                <Select
                  name="rating"
                  styles={formDropdownStyle}
                  value={selectedRating}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={questionFields.ratings}
                  onChange={onRatingSelect}
                />

                <ErrorMessage
                  name="rating"
                  component="div"
                  className="invalid-feedback"
                />

                <Form.Label className="mt-3">Tipas</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  styles={formDropdownStyle}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      questionOptions: [
                        {
                          value: "",
                          weight: undefined,
                          hasRecommendations: false,
                          minValue: undefined,
                          recommendations: [],
                          min: undefined,
                          max: undefined,
                          step: undefined,
                        },
                      ],
                      questionShows: [],
                    });
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  isInvalid={!!formik.errors.type && formik.getFieldMeta("type").touched}
                  className="form__dropdown"
                >
                  <option></option>
                  {questionFields.type.map((t) => (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  ))}
                </Form.Control>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="invalid-feedback"
                />

                <br />
                {renderQuestionOptions(formik.values.type, formik)}
                <br />

                  <Form.Row className="d-flex justify-content-between">
                  <Form.Check
                    label="Rodymas pagal sąlygas"
                    type="checkbox"
                    name="conditionalShow"
                    value={formik.values.conditionalShow}
                    onChange={(e) => {
                        formik.handleChange(e)
                        if (e.target.checked === false) {
                            formik.values.isAlwaysCalculated = false;
                        }
                    }
                    }
                    onBlur={formik.handleBlur}
                    checked={formik.values.conditionalShow}
                  />
                      {formik.values.conditionalShow &&
                    <Form.Check
                        label="Balai prarandami jei neparodytas"
                        type="checkbox"
                        name="isAlwaysCalculated"
                        value={formik.values.isAlwaysCalculated}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isAlwaysCalculated}
                    />
                      }
                  </Form.Row>

                <QuestionShows
                  formik={formik}
                  surveyId={surveyId}
                />
              </Form.Group>
              <FormButton
                text={isEditQuestion ? "Išsaugoti pakeitimus" : "Išsaugoti naują klausimą"}
                type="submit"
                variant="success"
                disabled={formik.isSubmitting}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default QuestionForm;
