import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import {Form} from 'react-bootstrap'
import * as Yup from 'yup';
import ratingApi from "../../api/ratingApi";
import {useHistory} from "react-router-dom";
import Field from "../../components/formik/elements/Field";
import FormHeading from "../../components/formik/elements/FormHeading";
import FormButton from "../../components/formik/elements/Button";
import Select from "react-select";
import formDropdownStyle from "../../styles/formDropdownStyle";

const RatingForm = ({match: {params: {ratingId}}}) => {

    const isEditRating = !!ratingId;
    const [rating, setRating] = useState({})

    useEffect(() => {
        isEditRating && ratingApi.fetchRating(ratingId).then(({data: rating}) => {
            setRating(rating)
            setSelectedScale(scales.find(scale => scale.id === rating.scale))
        })
    }, [])

    let history = useHistory();

    const scales = [
        {id: "EXPORT_EXPERIENCE", name: "Eksporto Patirtis"},
        {id: "EXPORT_GROWTH", name: "Pasirengimas eksporto augimui"}
    ]

    const [selectedScale, setSelectedScale] = useState("");

    const onSelectChange = (value) => {
        setSelectedScale(value);
    };

    const initialState = {
        name: isEditRating ? rating.name : '',
        weight: isEditRating ? rating.weight : '',
        scale: isEditRating ? rating.scale : ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .label("Pavadinimas negali būti tuščias!")
            .required(),
        weight: Yup.number()
            .label("Svoris negali būti tuščias!")
            .min(1)
            .required(),
    })

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text={`${isEditRating ? "Redaguoti" : "Sukurti"} vertinimą`}/>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                enableReinitialize={isEditRating}
                onSubmit={values => {
                    if (isEditRating) {
                        values.id = ratingId;
                        ratingApi.updateRating(values)
                            .then((res) => history.push("/cms/rating"))
                    } else {
                        ratingApi.addRating(values)
                            .then((res) => history.push("/cms/rating"))
                    }

                }}
            >
                {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Form.Group>
                            <Field label="Pavadinimas"
                                   type={"text"}
                                   name={"name"}
                                   formik={formik}
                            />
                            <Field label="Svoris"
                                   type={"number"}
                                   name={"weight"}
                                   formik={formik}/>
                            <Form.Label className="mt-3">Skalė</Form.Label>
                            <Select
                                placeholder=""
                                name="scale"
                                styles={formDropdownStyle}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={selectedScale}
                                options={scales}
                                onChange={(value) => {
                                    onSelectChange(value);
                                    formik.setFieldValue("scale", value.id);
                                }}
                            />
                        </Form.Group>
                        <FormButton
                            type="submit"
                            variant="success"
                            disabled={formik.isSubmitting}
                            text={isEditRating ? "Išsaugoti pakeitimus" : "Kurti naują"}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RatingForm;