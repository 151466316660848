import React, {useEffect, useState} from "react";
import {Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import surveyApi from "../../api/surveyApi";
import FormHeading from "../../components/formik/elements/FormHeading";
import FormButton from "../../components/formik/elements/Button";

export default () => {

    const [surveys, setSurveys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        surveyApi.fetchSurveys()
            .then(response => setSurveys(response.data))
            .finally(() => setIsLoading(false));
    }, [])

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Apklausų sąrašas"/>
            {isLoading && <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
            {surveys.length > 0 && <Table striped bordered hover className="mt-3" size="sm">
                <thead>
                <tr>
                    <th>Apklausos pavadinimas</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {surveys.map(s => (
                    <tr key={s.id}>
                        <td className="w-100">{s.name}</td>
                        <td>
                            <Link to={`/cms/survey/${s.id}`}>
                                <FormButton isForTable text="Temos"/>
                            </Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>}
        </div>
    )
}