import FormHeading from "../../components/formik/elements/FormHeading";
import React from "react";
import {Form} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import FormButton from "../../components/formik/elements/Button";
import * as Yup from "yup";
import {registerAttendee} from "../../api/attendeeApi";
import {useHistory} from "react-router-dom";

const CompanyForm = () => {

    const history = useHistory();

    const handleRegister = (values, setSubmitting) => {
        console.log("values", values)
        registerAttendee(values).then(()=>{
            history.push(
                `/cms/companies`
            );
        })
    }

    const newCompanyValidationSchema = Yup.object().shape({
        companyName: Yup.string().required("Būtina nurodyti pavadinimą"),
        email: Yup.string()
            .email("Netinkamas el. pašto adreso formatas")
            .required("Būtina nurodyti el. paštą"),
    });

    return(
        <>
            <div className="container">
                <FormHeading text="Naujos įmonės registravimas"/>
                <Formik
                    initialValues={{companyName: "", email: ""}}
                    validationSchema={newCompanyValidationSchema}
                    onSubmit={({...values}, {
                        setSubmitting,
                    }) => handleRegister(values, setSubmitting)}>
                    {(formik) =>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit(e)
                        }}>
                            <Form.Label className="mt-3">Įmonės pavadinimas:</Form.Label>
                            <Form.Control
                                type="text"
                                name="companyName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <ErrorMessage name="companyName" component="small" className="form__validation-error"/>
                            <Form.Label className="mt-3">El. paštas:</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <ErrorMessage name="email" component="small" className="form__validation-error"/>
                            <div className="mt-3">
                                <FormButton type="submit" text="Registruoti"/>
                            </div>
                        </Form>
                    }
                </Formik>
            </div>
        </>
    )
}

export default CompanyForm;