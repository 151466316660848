import HTTP from './index'

export default {
    fetchRecommendations() {
        return HTTP.get(`survey/1/recommendation/public/general`)
    },

    updateRecommendation(values) {
        return HTTP.put(`/survey/1/recommendation/public/general`, values)
    },

    addRecommendation(values) {
        return HTTP.post(`/survey/1/recommendation/public/general`, values)
    },

    deleteRecommendation(id) {
        return HTTP.delete(`/survey/1/recommendation/public/general/${id}`)
    },

    fetchRecommendation(id) {
        return HTTP.get(`/survey/1/recommendation/public/general/${id}`)
    },
}