import FormHeading from "../../components/formik/elements/FormHeading";
import React, {useEffect, useState} from "react";
import * as attendeeApi from "../../api/attendeeApi";
import FormButton from "../../components/formik/elements/Button";
import {Link} from "react-router-dom";
import CompaniesTable from "./table/CompaniesTable";
import {confirmAttendee, deleteAttendee, inviteAttendee} from "../../api/attendeeApi";
import ErrorAlert from "../error/ErrorAlert";
import DeleteConfirm from "../survey/modals/DeleteConfirm";
import InviteConfirm from "../survey/modals/InviteConfirm";
import ConfirmConfirm from "../survey/modals/ConfirmConfirm";

const CompaniesOverview = () => {

    const [attendeePage, setAttendeePage] = useState({
        attendees: [],
        activePage: 1,
        totalPages: null,
        itemsCountPerPage: null,
        totalItemsCount: null
    })

    const [error, setError] = useState(null)
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
    const [showInviteConfirmationModal, setShowInviteConfirmationModal] = useState(false)
    const [showConfirmConfirmationModal, setShowConfirmConfirmationModal] = useState(false)
    const [companyToDelete, setCompanyToDelete] = useState({})
    const [companyToInvite, setCompanyToInvite] = useState({})
    const [companyToConfirm, setCompanyToConfirm] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmationModal(false);
    const handleCloseInviteConfirmation = () => setShowInviteConfirmationModal(false);
    const handleCloseConfirmConfirmation = () => setShowConfirmConfirmationModal(false);

    useEffect(() => {
        fetchAttendeePage(attendeePage.activePage - 1, 10)
    }, [])

    const fetchAttendeePage = (page, size) => {
        attendeeApi.fetchAttendeesPage(page, size).then(res => {
            setAttendeePage(prevState => ({
                ...prevState,
                attendees: res.data.content,
                totalPages: res.data.totalPages,
                itemsCountPerPage: res.data.size,
                totalItemsCount: res.data.totalElements
            }));
        })
    }

    const setActivePage = (pageNumber, size) => {
        setAttendeePage(prevState => ({
            ...prevState,
            activePage: pageNumber,
            itemsCountPerPage: size
        }));
        fetchAttendeePage(pageNumber - 1, size)
    }

    const handleInviteClicked = (company) => {
        setCompanyToInvite(company)
        setShowInviteConfirmationModal(true)
    }

    const handleTableChange = (type, {page, sizePerPage}) => {
        setActivePage(page, sizePerPage)
    }

    const handleDeleteClicked = (company) => {
        setCompanyToDelete(company)
        setShowDeleteConfirmationModal(true)
    }

    const handleConfirmClicked = (company) => {
        setCompanyToConfirm(company)
        setShowConfirmConfirmationModal(true)
    }

    const deleteCompany = (id) => {
        setIsLoading(true)
        deleteAttendee(id).then(() => fetchAttendeePage(attendeePage.activePage - 1, 10))
            .catch(err => {
                setError("Nenumatyta klaida. Bandykite perkrauti puslapį.")
                console.log("err", err)
            })
            .finally(() => {
                setIsLoading(false)
                setCompanyToDelete({})
                setShowDeleteConfirmationModal(false)
            })
    }

    const inviteCompany = (uuid) => {
        setIsLoading(true)
        inviteAttendee(uuid).then(() => fetchAttendeePage(attendeePage.activePage - 1, 10))
            .catch(err => {
                setError("Nenumatyta klaida. Bandykite perkrauti puslapį.")
                console.log("err", err)
            })
            .finally(() => {
                setIsLoading(false)
                setCompanyToInvite({})
                setShowInviteConfirmationModal(false)
            })
    }
    
    const confirmCompany = (uuid) => {
        setIsLoading(true)
        confirmAttendee(uuid).then(() => fetchAttendeePage(attendeePage.activePage - 1, 10))
            .catch(err => {
                setError("Nenumatyta klaida. Bandykite perkrauti puslapį.")
                console.log("err", err)
            })
            .finally(() => {
                setIsLoading(false)
                setCompanyToConfirm({})
                setShowConfirmConfirmationModal(false)
            })

    }

    return (
        <div className="container-fluid w-90">
            {error && <ErrorAlert error={error}/>}
            {!error &&
            <>
                <FormHeading text="Įmonių sąrašas"/>
                <Link to={`/cms/companies/new`}>
                    <FormButton text="Registruoti naują"/>
                </Link>
                {attendeePage.attendees.length === 0
                    ? <p>Registruotų įmonių sąrašas tuščias.</p>
                    : <CompaniesTable
                        data={attendeePage.attendees}
                        page={attendeePage.activePage}
                        sizePerPage={attendeePage.itemsCountPerPage}
                        totalSize={attendeePage.totalItemsCount}
                        onTableChange={handleTableChange}
                        sendInvite={handleInviteClicked}
                        handleDelete={handleDeleteClicked}
                        handleConfirm={handleConfirmClicked}
                    />
                }
                <DeleteConfirm
                    showConfirmationModal={showDeleteConfirmationModal}
                    handleClose={handleCloseDeleteConfirmation}
                    companyToDelete={companyToDelete}
                    isLoading={isLoading}
                    handleConfirm={() => deleteCompany(companyToDelete.id)}
                />
                <InviteConfirm
                    showConfirmationModal={showInviteConfirmationModal}
                    handleClose={handleCloseInviteConfirmation}
                    companyToInvite={companyToInvite}
                    isLoading={isLoading}
                    handleConfirm={() => inviteCompany(companyToInvite.attendeeCode)}
                />
                <ConfirmConfirm
                    showConfirmationModal={showConfirmConfirmationModal}
                    handleClose={handleCloseConfirmConfirmation}
                    companyToConfirm={companyToConfirm}
                    isLoading={isLoading}
                    handleConfirm={() => confirmCompany(companyToConfirm.attendeeCode)}
                />
            </>
            }
        </div>
    );
}

export default CompaniesOverview;