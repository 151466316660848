import React, {useContext} from "react";
import {TargetCountryContext} from "../../App";
import {ErrorMessage} from "formik";
import Select from "react-select";
import FormButton from "../../components/formik/elements/Button";
import FormHeading from "../../components/formik/elements/FormHeading";
import Label from "../../components/formik/elements/Label";
import {countries as countriesLt} from "./countryList/lt.json"
import formDropdownStyle from "../../styles/formDropdownStyle";

const CountrySelect = ({handleClick, formik}) => {

    const countries = Object.entries(countriesLt).map(([value, label]) => {
        return {value, label}
    })

    countries.sort((a, b) => a.label.localeCompare(b.label, 'lt', { sensitivity: 'base' }))

    const {targetCountry, setTargetCountry} = useContext(TargetCountryContext)

    const handleChange = (country, form) => {
        form.setFieldValue("country", country);
        setTargetCountry(country);
    }

    const continueIfValid = () => {
        formik.validateForm().then(validationErrors => {
            formik.setTouched({country: true});
            !validationErrors.country && handleClick();
        });
    }

    return (
        <div className="progress__content-offset">
            <FormHeading text="Prioritetinė rinka eksportui"/>
            <Label
                text="Pasirinkite vieną labiausiai prioritetinę rinką jūsų eksporto plėtrai artimiausiu metu (jai bus skirta dalis testo klausimų)"/>
            <Select
                name="country"
                styles={formDropdownStyle}
                placeholder="Pasirinkti..."
                options={countries}
                onChange={(country) => handleChange(country, formik)}
                value={targetCountry}
            />
            <ErrorMessage name="country" component="div" className="form__validation-error"/>
            <FormButton type="button" text="Tęsti" onClick={() => continueIfValid()}/>
        </div>
    )
}
export default CountrySelect;