import React from "react";
import "../../../styles/listGroup.css"

export default ({ items }) => (
  <ul class="list-group list-group-horizontal boxList">
    <li class="list-group-item">Vidurkis</li>
    {items['Bazinis įmonės pasiruošimas'] && (
      <>
        <li class="list-group-item one-item">{Math.round(items['Bazinis įmonės pasiruošimas'])}</li>
        <li class="list-group-item two-item">{Math.round(items['Tikslinių rinkų ir segmentų pažinimas'])}</li>
        <li class="list-group-item three-item">{Math.round(items['Produktų / paslaugų paruošimas eksportui'])}</li>
        <li class="list-group-item four-item">{Math.round(items['Rinkodara ir viešieji ryšiai'])}</li>
        <li class="list-group-item five-item">{Math.round(items['Eksporto pardavimų proceso valdymas ir įgūdžiai'])}</li>
        <li class="list-group-item six-item">{Math.round(items['Pasiryžimas plėsti eksportą ir veiksmų planavimas'])}</li>
        <li class="list-group-item seven-item">{Math.round(items['Eksporto kanalų diversifikavimas'])}</li>
        <li class="list-group-item eight-item">{Math.round(items['Eksporto dalis apyvartoje'])}</li>
        <li class="list-group-item nine-item">{Math.round(items['Eksporto istorija'])}</li>
        <li class="list-group-item ten-item">{Math.round(items['Eksporto geografija'])}</li>
        <li class="list-group-item eleven-item">{Math.round(items['Eksporto apimtys'])}</li>
      </>
    )}
  </ul>
)
