import HTTP from './index';
import { prepareAnswersArray } from './utils';

export default {
    fetchSurveys() {
        return HTTP.get(`/survey`)
    },

    fetchSurveyQuestionnaires(id) {
        return HTTP.get(`/survey/${id}`)
    },

    storeNewSurveyAttempt(formValues, surveyId) {

        let answersArray = prepareAnswersArray(formValues);

        const values = {
          attendee: formValues.attendee,
          answers: answersArray,
          surveyId
        };
        return HTTP.post(`/survey/${surveyId}/attempt/public`, values)
    },

    fetchAttendeeSurveyResult(attendeeCode) {
        return HTTP.get(`/survey/1/attempt/public/attendee/${attendeeCode}`);
    }
}