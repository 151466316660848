import {Alert} from "react-bootstrap";
import React from "react";

const ErrorAlert = ({error}) => (
    <Alert variant="warning" className="d-flex flex-column align-items-center mt-3">
        {error}
        <br/>
    </Alert>
);

export default ErrorAlert;