import HTTP from './index'

export default {
    fetchRatings() {
        return HTTP.get(`/survey/1/rating`)
    },

    addRating(values) {
        return HTTP.post(`/survey/1/rating`, values)
    },

    updateRating(values) {
        return HTTP.put(`/survey/1/rating`, values)
    },

    fetchRating(id) {
        return HTTP.get(`/survey/1/rating/${id}`)
    },

    deleteRating(id) {
        return HTTP.delete(`/survey/1/rating/${id}`)
    }
}