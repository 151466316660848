const DESC_BEGINNER = "Jūsų įmonė dar tik žengia pirmuosius žingsnius užsienio rinkose, o aukštam eksporto plėtros tempui pasiekti jums derėtų atlikti papildomus pasirengimo veiksmus. Daugiau dėmesio skirkite rentabilaus verslo modelio išvystymui, susipažinimui su potencialiausiomis rinkomis, produktų / paslaugų adaptacijai užsienio vartotojams, rinkodaros plano ir priemonių tobulinimui, kompetentingo eksporto specialisto pritraukimui, tikslų nusistatymui. Detalesnę informaciją apie jūsų įvertinimą pagal atskirus kriterijus ir konkrečias rekomendacijas rasite apatinėje puslapio dalyje."
const DESC_PROMISING = "Jūsų įmonė dar tik žengia pirmuosius žingsnius užsienio rinkose, tačiau yra teisingame kelyje. Ilgametės eksporto patirties trūkumas reiškia, kad dar neturite plataus klientų rato visame pasaulyje ir negalite remtis vien tik praeities įdirbiu. Kita vertus, jūsų eksporto procesai yra gerai sustyguoti, tad sėkmė eksporto rinkose yra tik laiko klausimas. Šiame etape svarbu investicijas ir energiją nukreipti tinkama kryptimi bei išlaikyti pakankamai ambicingus (tačiau įgyvendinamus) tikslus. Detalesnę informaciją apie jūsų įvertinimą pagal atskirus kriterijus ir konkrečias rekomendacijas rasite apatinėje puslapio dalyje."
const DESC_EXPERIENCED = "Jūsų įmonė yra patyrusi eksportuotoja, tačiau jūsų varomąja eksporto jėga didžiąja dalimi yra tapęs praeities įdirbis. Pasaulis nuolat keičiasi, tad tikintis tolimesnio augimo rekomenduotina į eksporto plėtrą pažvelgti iš naujos perspektyvos. Jums derėtų sistemingiau planuoti eksporto strategiją, išbandyti įvairesnius klientų pritraukimo metodus, pasitelkti šiuolaikiškus eksporto vadybos modelius, patobulinti ir praplėsti rinkodaros priemones.  Detalesnę informaciją apie jūsų įvertinimą pagal atskirus kriterijus ir konkrečias rekomendacijas rasite apatinėje puslapio dalyje."
const DESC_LEADER = "Jūsų įmonė jau yra pasiekusi aukštą brandos eksportuojant lygmenį. Užsienio rinkose jūs jaučiatės kaip namie, o eksporto procesai yra gerai sustyguoti. Šiame etape tolimesnį augimą pasiekti padėtų rinkų geografijos ar asortimento praplėtimas, dar intensyvesnė adaptacija esamose rinkose, padidintos investicijos į naujas ar jau pasiteisinusias rinkodaros priemones. Detalesnę informaciją apie jūsų įvertinimą pagal atskirus kriterijus ir konkrečias rekomendacijas rasite apatinėje puslapio dalyje."

export const categories = {
    BEGINNER: {
        grade: 1,
        title: "Pradedantysis",
        description: DESC_BEGINNER,
        color: "var(--category-beginner)"
    },
    PROMISING: {
        grade: 2,
        title: "Perspektyvus",
        description: DESC_PROMISING,
        color: "var(--category-promising)"
    },
    EXPERIENCED: {
        grade: 3,
        title: "Patyręs",
        description: DESC_EXPERIENCED,
        color: "var(--category-experienced)"
    },
    LEADER: {
        grade: 4,
        title: "Lyderis",
        description: DESC_LEADER,
        color: "var(--category-leader)"
    }
}

export const levels = {
    LOW: {
        name: "Žemas įvertinimas",
        color: "#ff3823",
    },
    MEDIUM: {
        name: "Vidutinis įvertinimas",
        color: "#ffd400"
    },
    HIGH: {
        name: "Aukštas įvertinimas",
        color: "#25408f"
    },
}

export const generalRecommendations = [
    {
        link: "https://www.verslilietuva.lt/eksportuok/",
        title: `„Versli Lietuva” paslaugos eksportuotojams`,
        message: `„Versli Lietuva” teikia informaciją ir konsultacijas eksporto pradžios ir plėtros klausimais, padeda užmegzti naujų verslo kontaktų ir partnerysčių. Šioje nuorodoje rasite visas šiuo metu aktualias agentūros iniciatyvas – nuo mokymų ir informacinės medžiagos apie pasiruošimą eksportui ir jo plėtrą iki užsienio įmonių ir viešųjų pirkimų duomenų bazių.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/eksporto-gidas/",
        title: `Eksporto gidas`,
        message: `„Eksporto gidas“ – tai vienas informacinis langelis Lietuvos eksportuotojams. Portale vartotojui draugišku būdu talpinama visa aktuali, eksportui palankias sąlygas kurianti, eksporto iniciatyvas skatinanti informacija apie pagrindines Lietuvos eksporto rinkas.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/10-zingsniu-eksporto-link/",
        title: `Leidinys „10 žingsnių eksporto link“`,
        message: `„10 žingsnių eksporto link“ yra elektroninis leidinys, skirtas pradedančiajam eksportuotojui. Leidinyje galima rasti pagrindinę informaciją ir patarimus, kaip nuspręsti, ar įmonė jau pasiruošusi eksportuoti, kaip atsirinkti tinkamas rinkas eksportui, kaip pasiruošti eksporto planą, kaip pasirinkti pardavimo būdą, kaip užmegzti prekybinius santykius su užsienio partneriais ir tinkamai prisistatyti verslo misijose arba parodose.`,
    },
    {
        link: "https://www.verslilietuva.lt/kalendorius/?theme=297",
        title: `„Versli Lietuva“ eksportui skirtų renginių kalendorius`,
        message: `Kalendoriuje pateikiami visi artimiausiu metu planuojami eksportui skirti „Versli Lietuva“ mokymai, bendri tarptautinių parodų stendai ir verslo misijų iniciatyvos bei kt. renginiai.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/brandlab-2/brandlab/",
        title: `„BrandLAB“ prekinių ženklų dirbtuvės`,
        message: `„Versli Lietuva“ kartu su rinkodaros ekspertais periodiškai rengiamų dirbtuvių tikslaI: suteikti būtinas žinias apie prekės ženklo kūrimą ir vystymą užsienio rinkose, sudaryti galimybes programos metu dalyviams susikurti ar atnaujinti prekės ženklą, pasiruošti strateginį prekės ženklo vystymo planą numatytai užsienio rinkai.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/uzsienio-partneriu-paieskos-duomenu-baze-orbis/",
        title: `Užsienio partnerių paieškos duomenų bazė „Orbis“`,
        message: `VšĮ „Versli Lietuva“ naudodamasi tarptautine įmonių duomenų baze „Orbis“ gali jums suteikti potencialių užsienio partnerių sąrašą ir bendrinius jų kontaktus – el. pašto adresą, nuorodą į internetinę svetainę, įmonės adresą.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/naudingos-es-priemones-eksportuojanciam-verslui/",
        title: `Naudingi Europos Komisijos įrankiai eksportuojančiam verslui`,
        message: `Europos Sąjunga siekia sudaryti kuo daugiau galimybių ES verslams prekiauti tarpusavyje ir su kitomis valstybėmis. Šios priemonės padeda eksportuojančioms įmonėms gauti reikalingas konsultacijas, susirasti partnerius užsienyje ir geriau žinoti savo galimybes pasaulio rinkose.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/eksportuotoju-duomenu-baze/",
        title: `Eksportuotojų duomenų bazė`,
        message: `Galite tapti „Versli Lietuva“ Lietuvos eksportuotojų duomenų bazės, kuria naudojasi patikimų partnerių ieškančios užsienio įmonės, dalimi. EDB skelbiama informacija apie mūsų šalies gamintojus ir paslaugų tiekėjus iš įvairių verslo sektorių. Duomenų bazėje pateikiami detalūs Lietuvos įmonių aprašymai, kontaktinė informacija.`,
    },
        {
        link: "https://klaster.lt/klasteriai/",
        title: `Sektoriniai klasteriai ir asociacijos`,
        message: `Į eksportą orientuoti klasteriai ir asociacijos organizuoja bendrus rinkos tyrimus, verslo misijas, dalyvavimą tarptautinėse parodose, mokymus. Nuorodoje pateikiamos dauguma Lietuvos verslo organizacijų, kurių nariais galėtumėte tapti ir jūs.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/tarptautiniu-viesuju-pirkimu-platforma-mercell/",
        title: `Tarptautinių viešųjų pirkimų platforma „Mercell“`,
        message: `„Versli Lietuva“ skatina Lietuvos įmones aktyviai įsitraukti į dalyvavimą tarptautiniuose viešuosiuose pirkimuose ir kviečia pasinaudoti tikslinių eksporto rinkų, tarp kurių yra ir Skandinavijos šalys, teikiamomis galimybėmis ir potencialu, prisijungiant prie visą Skandinavijos rinką apimančios viešųjų pirkimų platformos „Mercell“.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/orbidal/",
        title: `Tarptautinių viešųjų pirkimų platforma „Orbidal“`,
        message: `„Versli Lietuva“ kviečia jungtis prie gausaus sėkmingai tarptautiniuose viešųjų pirkimų konkursuose dalyvaujančių įmonių būrio ir išnaudoti tarptautinių viešųjų pirkimų platformos „Orbidal“ galimybes.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/nato-tarptautiniai-viesieji-pirkimai/",
        title: `NATO tarptautiniai viešieji pirkimai`,
        message: `Sekite nuolat atnaujinamą informaciją apie NATO skelbiamus tarptautinius viešuosius pirkimus, kurių metu pateikiama neįslaptinta informacija, ir pasinaudokite galimybe sudalyvauti tarptautiniuose viešuosiuose pirkimuose`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/biotechnologiju-partneriu-paieskos-duomenu-baze-biotechgate/",
        title: `Biotechnologijų partnerių paieškos duomenų bazė „Biotechgate“`,
        message: `„Biotechgate“ duomenų bazė yra skirta biotechnologijų sektoriui ir joje yra beveik 60 000  įmonių iš viso pasaulio. Šią duomenų bazę galima naudoti ieškant naujų klientų, tiekėjų, investuotojų.`,
    },
    {
        link: "https://www.verslilietuva.lt/eksportuok/konsultacijos-norintiems-eksportuoti/",
        title: `Konsultacijos norintiems eksportuoti`,
        message: `Lietuvos diplomatinės atstovybės kitose šalyse prisideda prie Lietuvos įmonių eksporto ieškodamos galimybių, potencialių partnerių bei padėdamos perprasti tos šalies verslo kultūrą.`,
    },
    {
        link: "https://www.youtube.com/user/versliLietuva/videos",
        title: `„Versli Lietuva” YouTube mokymų archyvas `,
        message: `Dešimties metų archyve sukaupti vaizdo įrašai iš „Verslios Lietuvos” mokymų apie eksportą. Įrašai apima diskusijas apie konkrečius sektorius ir rinkas, bendro pobūdžio seminarus apie eksporto procesų valdymą, rinkodarą, derybas, sėkmės istorijas.  `,
    },
    {
        link: "https://www.verslilietuva.lt/pleskis/internete-karantino-nera/mokymu-mediateka/",
        title: `El. komercijos mokymų mediateka`,
        message: `„Versli Lietuva“ organizuoja mokymus apie rinkodarą ir pardavimus internetinėje erdvėje, kuriuos veda vieni geriausių šios srities specialistų Lietuvoje. Visų jau įvykusių pranešimų įrašus galite rasti nuorodoje.`,
    },
    {
        link: "http://lvpa.lt/lt/verslo-priemones",
        title: `LVPA parama eksportui`,
        message: `Lietuvos verslo paramos agentūra (LVPA) periodiškai skelbia kvietimus gauti ES paramą eksporto veikloms: padengti dalį tarptautinių parodų dalyvio mokesčių, sertifikatų gavimo ir kt. išlaidas.`,
    },
    {
        link: "https://mita.lrv.lt/lt/veiklos-sritys/programos-priemones",
        title: `MITA parama verslo tarptautiškumui`,
        message: `Mokslo, inovacijų ir technologijų agentūra (MITA) yra atsakinga už inovatyvių verslų finansavimą. Per  „Horizontas”, „EUREKA”, „Eurostars”, „MTEP rezultatų komercinimas” ir kt. ES finansavimo priemones skirstoma parama naujų produktų ir paslaugų komercializavimui.`,
    },
    {
        link: "https://invega.lt/verslui/20",
        title: `INVEGA parama eksportui`,
        message: `INVEGA teikia finansavimą įvairių brandos lygių įmonėms. Vienas iš aktualių instrumentų eksportuotojams – „Eksporto kredito garantijos“, leidžiančios sumažinti užsienio pirkėjų nemokumo riziką.`,
    },
]
