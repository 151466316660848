import React from "react";
import "../../../styles/select.css"

export default ({ items, onChange}) => (
  <select onChange={onChange} class="form-select select-box" aria-label="Default select example">
    {items?.map((i, ind) => (
      <option key={i} value={ind}>{i}</option>
    ))}
  </select>
)
