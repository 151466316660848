import React, {useState, useEffect} from "react";
import {Alert, Spinner} from "react-bootstrap";

import surveyApi from "../../api/surveyApi";
import AttendeeDetails from './Diagnostics/AttendeeDetails';
import DiagnosticsHeading from "./Diagnostics/DiagnosticsHeading";
import DiagnosticCategories from "./Diagnostics/DiagnosticCategories";
import DiagnosticEstimatesHeader from "./Diagnostics/DiagnosticEstimatesHeader";
import PrintButton from "./Diagnostics/PrintButton";
import DiagnosticScaleDetails from "./Diagnostics/DiagnosticScaleDetails";
import DiagnosticRecommendationsResultBased from "./Diagnostics/DiagnosticRecommendationsResultBased";
import DiagnosticRecommendationsCommon from "./Diagnostics/DiagnosticRecommendationsCommon";
import DiagnosticGraphicalResult from "./Diagnostics/DiagnosticGraphicalResult";
import FormButton from "../../components/formik/elements/Button";
import AnswerList from "./Diagnostics/AnswerList";


const SurveyAttemptFinish = ({match, isDetail}) => {
    const [surveyAttendee, setSurveyAttendee] = useState(null);
    const [surveyDiagnostics, setSurveyDiagnostics] = useState(null);
    const [sectorAverage, setSectorAverage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchSurveyAttendeeResults();
    }, []);

    const fetchSurveyAttendeeResults = () => {
        setIsLoading(true);
        const attendeeCode = match.params.attendee;
        surveyApi
            .fetchAttendeeSurveyResult(attendeeCode)
            .then(({data: {attendee, surveyDiagnostics, sectorAverage}}) => {
                // uncomment lines below to test UI for various different estimates
                // surveyDiagnostics.detailedEstimates[0].scaleTotalEstimate = 19.4;
                // surveyDiagnostics.detailedEstimates[1].scaleTotalEstimate = 45.1;
                setSurveyDiagnostics(surveyDiagnostics);
                setSectorAverage(sectorAverage)
                setSurveyAttendee(attendee);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(
                    "Įvyko nenumatyta sistemos klaida. Prašome perkrauti puslapį."
                );
            })
            .finally(() => setIsLoading(false));
    };

    const errorAlert = (
        <Alert variant="warning" className="d-flex flex-column align-items-center mt-3">
            {isError}
            <br/>
            <FormButton onClick={() => fetchSurveyAttendeeResults()} text="Perkrauti"/>
        </Alert>
    );

    return !isLoading ? (
        <div className="container-fluid justify-content-center w-100 col-xl-7">
            {isError && errorAlert}
            {surveyDiagnostics && surveyAttendee &&
            <div>
                <DiagnosticsHeading text={isDetail ? "ĮMONĖS EKSPORTO PATIRTIES IR PROCESŲ ĮVERTINIMO DETALI APŽVALGA" : "JŪSŲ ĮMONĖS EKSPORTO PATIRTIES IR PROCESŲ ĮVERTINIMAS"}/>
                <DiagnosticCategories surveyDiagnostics={surveyDiagnostics}/>
                <DiagnosticEstimatesHeader surveyDiagnostics={surveyDiagnostics}/>
                <DiagnosticGraphicalResult surveyDiagnostics={surveyDiagnostics} sectorAverage={sectorAverage}/>
                <DiagnosticScaleDetails surveyDiagnostics={surveyDiagnostics} isDetail={isDetail} sectorAverage={sectorAverage}/>
                {isDetail && <AnswerList surveyDiagnostics={surveyDiagnostics}/>}
                <DiagnosticRecommendationsResultBased surveyDiagnostics={surveyDiagnostics}/>
                <DiagnosticRecommendationsCommon/>
                <AttendeeDetails attendee={surveyAttendee}/>
                <PrintButton/>
                <center><b>Įrankis nuolat tobulinamas, tad ateityje, norint pamatyti atnaujintas rekomendacijas, jums nereikės iš naujo pildyti klausimyno - tiesiog išsaugokite ir naršyklėje vėl pakraukite šio puslapio URL nuorodą.</b></center>
            </div>
            }
            <br/>


        </div>
    ) : (
        <div className="d-flex justify-content-center m-5">
            <Spinner animation="border" role="status" className="m-3" variant="warning"/>
        </div>
    );
}
export default SurveyAttemptFinish;
