import EstimateIndicator from "./EstimateIndicator";

const Recommendation = ({message, title, link, ratingResult, id}) => {

    const renderMessage = (message, id) => {
        return (
            <div className="mt-3" key={id}>
                <span dangerouslySetInnerHTML={{__html: message}}/>
            </div>
        )
    }


    const renderResultBased = () => {
        return (
            <>
                <div>
                    <EstimateIndicator estimate={Math.round(ratingResult.ratingTotalEstimate)} sm hasLevelName noSector/>
                    <div className="mb-3 d-flex">
                        <div className="p-2 pl-4 pr-4 recommendation__tag ">
                            <strong>
                                {ratingResult.ratingName}
                            </strong>
                        </div>
                    </div>
                </div>
                {ratingResult.ratingRecommendations.map(({message, id}) => renderMessage(message, id))}
            </>
        )
    }

    const renderCommon = () => {
        return (
            <>
                <div className="d-flex justify-content-between">
                <span>
                    <strong>
                        {title}
                    </strong>
                </span>
                    <a href={link} target="_blank" rel="noreferrer">Daugiau informacijos</a>
                </div>
                {renderMessage(message, id)}
            </>
        )
    }

    return (
        <div className="recommendation pl-4 pr-4 pt-2 pb-2 mb-3">
            {
                !!ratingResult
                    ? renderResultBased()
                    : renderCommon()
            }
        </div>
    )
}

export default Recommendation;