import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import ConjunctionSelect from "./ConjunctionSelect";
import NegateCheckbox from "./NegateCheckbox";

const ObservedValue = ({condition, questionOptions, formik, topIndex, index}) => {
    const notCondition = condition.notCondition
    const conditionFieldName = `questionShows[${topIndex}].questionShowConditions[${index}]`
    const notConditionFieldName = `${conditionFieldName}.notCondition`

    function onValueChanged(event) {

        let qShows = [...formik.values.questionShows];
        // we have only 1 option always
        qShows[topIndex].questionShowConditions[index].questionOptions[0] = {id: "" + questionOptions[0].id};
        formik.setValues({
            ...formik.values,
            questionShows: [...qShows]
        });

        formik.handleChange(event);
    }

    return (
        <div>
            <Row className="d-flex justify-content-end">
                {index > 0 &&
                <Col xs="auto">
                    <ConjunctionSelect
                        formik={formik}
                        name={`questionShows[${topIndex}].questionShowConditions[${index}].conditionType`}
                        value={formik.values.questionShows[topIndex].questionShowConditions[index].conditionType}
                    />
                </Col>
                }
                <Col xs="auto">
                    <div className="mt-2 d-flex justify-content-end">
                        <span>Buvo nurodyta reikšmė</span>
                    </div>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                    <NegateCheckbox formik={formik} name={notConditionFieldName} value={notCondition} condition={condition}/>
                </Col>
                <Col xs="auto">
                    <Form.Control as="select"
                                  name={`questionShows[${topIndex}].questionShowConditions[${index}].compareSign`}
                                  value={formik.values.questionShows[topIndex].questionShowConditions[index].compareSign}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                    >
                        <option value=""></option>
                        <option value="EQUALS">=</option>
                        <option value="GREATER">&gt;</option>
                        <option value="GREATEROREQUALS">&gt;=</option>
                        <option value="LESS">&lt;</option>
                        <option value="LESSOREQUALS">&lt;=</option>
                    </Form.Control>
                </Col>
                <Col xs={3}>
                    <Form.Control type="text"
                                  name={`questionShows[${topIndex}].questionShowConditions[${index}].answerValue`}
                                  value={formik.values.questionShows[topIndex].questionShowConditions[index].answerValue}
                                  onChange={(e) => {
                                      onValueChanged(e)
                                  }}
                                  onBlur={formik.handleBlur}
                    />
                </Col>
            </Row>
            <hr/>
        </div>
    );
}

export default ObservedValue;