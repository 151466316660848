import Hero from "./Hero";
import Benefits from "./Benefits";
import HowItWorks from "./HowItWorks";
import Features from "./Features";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";

const LandingPage = ({onStart}) => {
    return (
        <>
                <Hero onStart={onStart}/>
                <Benefits/>
                <HowItWorks/>
                <Features onStart={onStart}/>
                {/*<Testimonials/>*/}
                <hr/>
                <ContactUs/>
        </>
    )
}

export default LandingPage;