import React from "react";
import {Table} from "react-bootstrap";
import DiagnosticsHeading from "./DiagnosticsHeading";

const AttendeeDetails = ({attendee}) => {
    const {
        attendeeName,
        companyName,
        companyWebsite,
        email,
        phoneNo,
    } = attendee;
    return (
        <>
            <DiagnosticsHeading text="TESTĄ ATLIKO"/>
            <Table striped borderless size="sm" hover className="diagnostics__attendee table-responsive-sm">
                <thead>
                <tr>
                    <th>Dalyvio vardas</th>
                    <th>Įmonės pavadinimas</th>
                    <th>Įmonės tinklalapis</th>
                    <th>Įmonės el. paštas</th>
                    <th>Įmonės telefonas</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{attendeeName}</td>
                    <td>{companyName}</td>
                    <td>{companyWebsite}</td>
                    <td>{email}</td>
                    <td>{phoneNo}</td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

export default AttendeeDetails;
