import React, {useEffect, useState} from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Field from "../../../components/formik/elements/Field";
import questionnaireApi from "../../../api/questionnaireApi";
import FormHeading from "../../../components/formik/elements/FormHeading";
import FormButton from "../../../components/formik/elements/Button";

const QuestionnaireForm = ({match: {params: {questionnaireId}}}) => {

  const isEditQuestionnaire = !!questionnaireId;
  let history = useHistory();

  const [questionnaire, setQuestionnaire] = useState({})

  useEffect(()=>{
      isEditQuestionnaire && questionnaireApi
          .fetchQuestionnaire(questionnaireId)
          .then(({data:questionnaire})=>{
          setQuestionnaire(questionnaire)
      })
  }, [])

  const initialState = {
    name: isEditQuestionnaire ? questionnaire.name : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().label("Pavadinimas negali būti tuščias!").required(),
  });

  return (
    <div className="container-fluid col-xl-7">
      <FormHeading text={`${isEditQuestionnaire ? "Pervadinti" : "Sukurti"} temą`} />
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        enableReinitialize={isEditQuestionnaire}
        onSubmit={(values) => {
            if (isEditQuestionnaire) {
                values.id = questionnaireId
                questionnaireApi.renameQuestionnaire(values).then((res) => {
                    history.push("/cms/survey/1");
                });
            } else {
                questionnaireApi.addQuestionnaire(values).then((res) => {
                    history.push("/cms/survey/1");
                });
            }
        }}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Field
                label="Pavadinimas"
                type={"text"}
                name={"name"}
                formik={formik}
              />
            </Form.Group>
            <FormButton
              type="submit"
              variant="success"
              disabled={formik.isSubmitting}
              text={isEditQuestionnaire ? "Išsaugoti pakeitimus" : "Kurti naują"}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionnaireForm;
