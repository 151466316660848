import React from "react";
import {Field as FormikField, ErrorMessage} from "formik";

export default ({text, linkText, linkUrl, name, isRequired}) => {
    return (
        <div className="form__checkbox">
            <FormikField type="checkbox" name={name} className="form__checkbox"/>
            <label htmlFor={name}>
                <span>
                    {text}
                    <a className="form__link ml-1" target="_blank" rel="noreferrer" href={linkUrl}>
                        {linkText}
                    </a>
                    {isRequired && <span className="form__label-hint"> (privaloma)</span>}
                </span>
                <ErrorMessage name={name} component="small" className="form__validation-error"/>
            </label>
        </div>
    )
}