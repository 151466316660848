const formDropdownStyle = {
    control: (props) => ({
        ...props,
        minHeight: "12px",
        "&:hover": { borderColor: "black" },
        borderColor: "black",
    }),
    option: (base, state) => ({...base,
        minHeight: "30px",
    }),
    dropdownIndicator: base => ({ ...base,
        color: "black",
        padding: 0,
        width: "90%",
        "&:hover": {
            color: "black"
        }}),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),
};

export default formDropdownStyle;