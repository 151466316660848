import cx from "classnames";

const ProgressBubble = ({isAccomplished, isInProgress, index, activeIndex, description}) => {


    const bubbleClasses = cx({
        "progress__bubble": true,
        "progress__bubble--accomplished": activeIndex > 0 && isAccomplished && !isInProgress,
        "progress__bubble--inProgress": isInProgress,

    })

    const descriptionClasses =  cx({
        "progress__description": true,
        "progress__description--accomplished": activeIndex > 0 && isAccomplished && !isInProgress,
        "progress__description--inProgress": isInProgress,
    })

    return (
        <div className="d-flex flex-column align-items-center align-content-end">
            <div className={bubbleClasses}>
                {index + 1}
            </div>
            <div className={descriptionClasses}>
                {description}
            </div>
        </div>
    )
}

export default ProgressBubble;