import React from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import ObservedValue from './ObservedValue';
import ObservedMultiselect from "./ObservedMultiselect";
import ObservedSingleSelect from "./ObservedSingleSelect";

const QuestionShowConditions = ({condition, formik, topIndex}) => {

    const {values} = formik;

    function renderOneCondition(subCondition, index) {
        switch (condition.type) {
            case 'INPUT':
            case 'SLIDER':
                return (
                    <ObservedValue condition={subCondition}
                                   questionOptions={condition.questionOptions}
                                   formik={formik}
                                   key={index}
                                   topIndex={topIndex}
                                   index={index}/>);
            case 'MULTI_SELECT':
                return (<ObservedMultiselect key={index}
                                             requirementIndex={topIndex}
                                             conditionIndex={index}
                                             currentCondition={subCondition}
                                             allOptionsOfObservedQuestion={condition.questionOptions}
                                             formik={formik}/>);
            case 'RADIO':
            case 'DROPDOWN':
                return (<ObservedSingleSelect key={index}
                                              requirementIndex={topIndex}
                                              conditionIndex={index}
                                              currentCondition={subCondition}
                                              allOptionsOfObservedQuestion={condition.questionOptions}
                                              formik={formik}/>);
            default:
                return (<span></span>);
        }
    }

    function addSubCondition() {

        let newCondition = {
            conditionType: 'AND',
            notCondition: false,
            compareSign: '',
            answerValue: '',
            questionOptions: []
        };

        let oldConditions = [...values.questionShows];
        oldConditions[topIndex].questionShowConditions.push(newCondition);
        formik.setValues({
            ...values,
            questionShows: oldConditions
        });
    }

    const describeAddCondition = (type) => {
        switch (type) {
            case 'INPUT':
            case 'SLIDER':
                return "+ Pridėti reikšmės reikalavimą"
            case 'MULTI_SELECT':
                return "+ Pridėti pasirinkimo kombinaciją"
            case 'RADIO':
            case 'DROPDOWN':
                return "+ Pridėti pasirinkimo variantą"
            default:
                return "+ Pridėti"
        }
    }

    return (
        <Container className="ml-5">
            <Col>
                {condition.questionShowConditions.map((c, index) => (
                    renderOneCondition(c, index)
                ))}
            </Col>
            <Button type="button"
                    variant="link"
                    onClick={addSubCondition}>
                {describeAddCondition(condition.type)}
            </Button>
        </Container>

    );
}

export default QuestionShowConditions;