import {Form} from "react-bootstrap";
import React from "react";
import {getIn} from 'formik';
import Label from "./Label";

export default ({label, name, type, formik, isValidating, placeHolder, isRequired, disabled, as}) => {

    const {handleChange, handleBlur, values, errors, touched} = formik;

    return (
        <>
            <Label text={label} hasHint={isRequired} hint={"(privaloma)"}/>
            <Form.Control
                type={type}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={getIn(values, name)}
                isInvalid={(getIn(touched, name) || isValidating) && getIn(errors, name)}
                className="form__input"
                placeholder={placeHolder}
                disabled={disabled}
                as={as || 'input'}
            />

            {(getIn(touched, name) || isValidating) && getIn(errors, name) ? (
                <Form.Control.Feedback type="invalid">{getIn(errors, name)}</Form.Control.Feedback>
            ) : null}
        </>
    );
}