import RatingRow from "./RatingRow";
import {getTitleByScale} from "../../../api/utils";
import EstimateIndicator from "./EstimateIndicator";

const Scale = ({details, sectorAverage}) => {
    const {scale: name, scaleTotalEstimate: estimate, ratingResults} = details;
    const integerEstimate = Math.round(estimate)
    
    return (
        <div className="scale mb-3">
            <div className="scale__header d-flex p-4 justify-content-between align-items-center">
                <h5>{getTitleByScale(name)}</h5>
                <EstimateIndicator estimate={integerEstimate} lg sectorAverage={sectorAverage} name={name}/>
            </div>
            <div>
                {
                    ratingResults.map(rating => (
                        <RatingRow key={rating.ratingId} rating={rating}/>
                    ))
                }
            </div>
        </div>
    )
}

export default Scale;