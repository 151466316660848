import FormHeading from "../../components/formik/elements/FormHeading";
import React from "react";
import {Form} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import FormButton from "../../components/formik/elements/Button";
import {loginUser} from "../../api/userApi";
import {useHistory, useLocation} from "react-router-dom"

const LoginPage = () => {

    const history = useHistory();
    const location = useLocation();

    const handleLogin = (values, setSubmitting, setFieldError) => {
        setSubmitting(true)
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        loginUser(values).then(res => {
            const {from} = location.state || {from: {pathname: '/cms'}};
            localStorage.setItem('token', res.headers.authorization)
            localStorage.setItem('user', JSON.stringify(res.data))
            history.push(from);
        }).catch((err) => {
            if (err.status === 401) {
                setFieldError("password", "Neteisingi prisijungimo duomenys")
            }
        }).finally(() => setSubmitting(false))
    }

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Reikalingas prisijungimas"/>
            <Formik
                initialValues={{username: "", password: ""}}
                onSubmit={({...values}, {
                    setSubmitting,
                    setFieldError
                }) => handleLogin(values, setSubmitting, setFieldError)}>
                {(formik) =>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit(e)
                    }}>
                        <Form.Label className="mt-3">Prisijungimo el. paštas:</Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <Form.Label className="mt-3">Slaptažodis:</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <ErrorMessage name="password" component="small" className="form__validation-error"/>
                        <div className="mt-3">
                            <FormButton type="submit" text="Prisijungti"/>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
}

export default LoginPage;