const Counter = () => {

    const count = `>500`

    return (
            <div className="counter d-flex justify-content-center align-items-center">
                <div className="counter__content d-flex flex-column justify-content-center align-items-center">
                    <span className="counter__count">{count}</span>
                    <span className="counter__description text-center w-75">Tiek įmonių jau pasinaudojo eksporto diagnostikos paslauga</span>
                </div>
            </div>
    )
}

export default Counter;