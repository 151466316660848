import BootstrapTable from "react-bootstrap-table-next";
import {getCompaniesTableColumns} from "./companiesTableColumns";
import paginationFactory from "react-bootstrap-table2-paginator";
import {sizePerPageRenderer} from "./sizePerPageRenderer";
import PropTypes from "prop-types";
import React from "react";

const CompaniesTable = ({data, page, sizePerPage, onTableChange, totalSize, sendInvite, handleDelete, handleConfirm}) => (
    <div>
        <BootstrapTable
            remote
            striped
            hover
            condensed
            bordered={false}
            keyField="id"
            data={data}
            columns={getCompaniesTableColumns(sendInvite, handleDelete, handleConfirm)}
            pagination={paginationFactory({page, sizePerPage, totalSize, sizePerPageRenderer})}
            onTableChange={onTableChange}
        />
    </div>
);

CompaniesTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            companyWebsite: PropTypes.string,
            companyName: PropTypes.string.isRequired,
            attendeeName: PropTypes.string,
            email: PropTypes.string.isRequired,
            targetCountry: PropTypes.string,
            phoneNo: PropTypes.string,
            attendeeCode: PropTypes.string.isRequired,
            isConfirm: PropTypes.bool.isRequired,
            isExport: PropTypes.bool.isRequired,
        })
    ).isRequired,
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
    totalSize: PropTypes.number.isRequired,
    addToCart: PropTypes.func
}

export default CompaniesTable;