import HTTP from './index'

export default {
    fetchQuestionnaire(id) {
        return HTTP.get(`/survey/1/questionnaire/${id}`)
    },

    renameQuestionnaire(values) {
        return HTTP.patch(`/survey/1/questionnaire`, values)
    },

    addQuestionnaire(questionnaire) {
        return HTTP.post(`/survey/1/questionnaire`, questionnaire)
    },

    deleteQuestionnaire(id) {
        return HTTP.delete(`/survey/1/questionnaire/${id}`)
    }
}