import {Form} from "react-bootstrap";
import React from "react";

const ConjunctionSelect = ({formik, name, value}) => {
    return (
        <>
            <Form.Control as="select"
                          name={name}
                          value={value}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
            >
                <option value="AND">IR</option>
                <option value="OR">ARBA</option>
            </Form.Control>
        </>
        )
}

export default ConjunctionSelect;