import {Modal} from "react-bootstrap";
import FormButton from "../../components/formik/elements/Button";

const SuccessSubmit = ({showConfirmationModal, handleClose}) => {
    return (
        <Modal className="modal" show={showConfirmationModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Your data has been sent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Export diagnostics will be sent to you via email
            </Modal.Body>
            <Modal.Footer className="d-flex justify-center">
                <FormButton text="OK" onClick={handleClose}/>
            </Modal.Footer>
        </Modal>
    )
}

export default SuccessSubmit