import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import ConjunctionSelect from "./ConjunctionSelect";
import NegateCheckbox from "./NegateCheckbox";

const ObservedMultiselect = ({
                                 currentCondition,
                                 allOptionsOfObservedQuestion,
                                 formik,
                                 requirementIndex,
                                 conditionIndex
                             }) => {


    const condition = formik.values.questionShows[requirementIndex].questionShowConditions[conditionIndex];
    const observedOptions = condition.questionOptions;
    const conjunction = condition.conditionType;
    const notCondition = condition.notCondition

    const conditionFieldName = `questionShows[${requirementIndex}].questionShowConditions[${conditionIndex}]`
    const observedOptionsFieldName = `${conditionFieldName}.questionOptions`
    const conjunctionFieldName = `${conditionFieldName}.conditionType`
    const notConditionFieldName = `${conditionFieldName}.notCondition`

    const observedOptionsIdList = observedOptions.map(option => option?.id);

    const handleChange = (e, optionIndex) => {
        const currentChoiceFieldName = `${observedOptionsFieldName}[${optionIndex}]`
        const currentCombination = [...observedOptions]
        const isChecked = e.target.checked;
        const choice = {id: parseInt(e.target.value)}
        if (isChecked) {
            formik.setFieldValue(currentChoiceFieldName, choice)
        } else {
            const updatedCombination = currentCombination.filter(option => option !== undefined && option.id !== choice.id);
            formik.setFieldValue(observedOptionsFieldName, updatedCombination)
        }
    }

    return (
        <div key={`${requirementIndex}cond${conditionIndex}`}>
            <Row>

                <Col xs="auto" className="d-flex mt-1 mb-1">
                    {conditionIndex > 0 &&
                    // do not show conjunction select for first condition as it has nothing to be compared against
                        <div>
                            <ConjunctionSelect formik={formik} name={conjunctionFieldName} value={conjunction}/>
                        </div>
                    }
                    <div className="m-1 ml-3">
                        {notCondition
                            ? <span>Pasirinkime <span style={{color:"blue"}}>nebuvo</span> tokios atsakymų kombinacijos: </span>
                            : <span>Į pasirinkimą įėjo ši atsakymų kombinacija: </span>
                        }
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs="auto" className="d-flex align-items-center">
                    <NegateCheckbox name={notConditionFieldName} value={notCondition} formik={formik}
                                    condition={condition} isForMulti/>
                </Col>
                <Col>
                    {
                        allOptionsOfObservedQuestion.map((option, optionIndex) => {
                                return (
                                    <div key={requirementIndex + conditionIndex + "" + optionIndex}>
                                        <Form.Check type="checkbox"
                                                    inline
                                                    name={`${conditionFieldName}.questionOptions[${optionIndex}]`}
                                                    value={option.id}
                                                    onChange={(e) => handleChange(e, optionIndex)}
                                                    onBlur={formik.handleBlur}
                                                    defaultChecked={observedOptionsIdList.includes(option.id)}
                                        />
                                        {option.value}
                                    </div>
                                )
                            }
                        )
                    }
                </Col>
            </Row>
            <hr/>
        </div>
    )
}

export default ObservedMultiselect;