import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import {Form} from 'react-bootstrap'
import * as Yup from 'yup';
import recommendationApi from "../../api/recommendationApi";
import {useHistory} from "react-router-dom";
import Field from "../../components/formik/elements/Field";
import FormHeading from "../../components/formik/elements/FormHeading";
import FormButton from "../../components/formik/elements/Button";
import ratingApi from "../../api/ratingApi";

const RecommendationForm = ({match: {params: {recommendationId}}}) => {
    const isEditRecommendation = !!recommendationId;
    const [recommendation, setRecommendation] = useState({})

    useEffect(() => {
        isEditRecommendation && recommendationApi
            .fetchRecommendation(recommendationId)
            .then(({data: recommendation}) => {
                setRecommendation(recommendation)
            }).catch((err) => {console.error(err)})
    }, [])

    let history = useHistory();

    const initialState = {
        link: isEditRecommendation ? recommendation.link : '',
        title: isEditRecommendation ? recommendation.title : '',
        message: isEditRecommendation ? recommendation.message : ''
    }

    const validationSchema = Yup.object().shape({
        link: Yup.string()
            .label("Nuoroda negali būti tuščias!")
            .required(),
        title: Yup.string()
            .label("Titulas negali būti tuščias!")
            .required(),
        message: Yup.string()
            .label("Pranešimą negali būti tuščias!")
            .required(),
    })

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text={`${isEditRecommendation ? "Redaguoti" : "Sukurti"} rekomendacija`} />
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                enableReinitialize={isEditRecommendation}
                onSubmit={values => {
                    if (isEditRecommendation) {
                        values.id = recommendationId;
                        recommendationApi.updateRecommendation(values)
                            .then((res) => history.push("/cms/recommendations"))
                    } else {
                        recommendationApi.addRecommendation(values)
                            .then((res) => history.push("/cms/recommendations"))
                    }
                }}
            >
                {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Form.Group>
                            <Field label="Nuoroda"
                                   type={"text"}
                                   name={"link"}
                                   formik={formik}
                            />
                            <Field label="Pavadinimas"
                                   type={"text"}
                                   name={"title"}
                                   formik={formik}/>
                            <Field label="Aprašymas"
                                   as="textarea"
                                   name={"message"}
                                   formik={formik}/>
                        </Form.Group>
                        <FormButton
                            type="submit"
                            variant="success"
                            disabled={formik.isSubmitting}
                            text={isEditRecommendation ? "Išsaugoti pakeitimus" : "Kurti naują"}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RecommendationForm;