import React from "react";
import {getExperienceEstimate, getGrowthEstimate} from "./categories/utils";

const Diagram = ({surveyDiagnostics, sectorAverage, category}) => {

    const fixedDecimalNumber = (number, places = 2) => parseFloat(number).toFixed(places);

    const result = {
        EXPORT_EXPERIENCE: fixedDecimalNumber(getExperienceEstimate(surveyDiagnostics)),
        EXPORT_GROWTH: fixedDecimalNumber(getGrowthEstimate(surveyDiagnostics)),
    }

    const average = {
        EXPORT_EXPERIENCE: fixedDecimalNumber(sectorAverage.EXPORT_EXPERIENCE),
        EXPORT_GROWTH: fixedDecimalNumber(sectorAverage.EXPORT_GROWTH),
    }

    const parameters = {
        // canvas - zone where the dot will be placed within
        canvas: {
            width: 320,
            height: 320,
        },
        dot: {
            width: 12,
            height: 12,
            color: category.color,
            colorAvg: `grey`,
            fontSize: 14,
            fontSizeAvg: 10,
        },
        axis: {
            label: {
                fontSize: 14
            }
        }
    }

    const dotHalfWidth = parameters.dot.width / 2;
    const dotHalfHeight = parameters.dot.height / 2;
    const axisBorderStyle = `1px solid var(--light-grey)`;

    const coordinates = {
        dot: {
            x100: parameters.canvas.width - dotHalfWidth,
            y100: dotHalfHeight,
            x0: 0 - dotHalfWidth,
            y0: parameters.canvas.width - dotHalfHeight,

        }
    }

    // calculates exact dot position on X axis based on diagnostics result and diagram parameters
    const calculateX = (data) => {
        const range = parameters.canvas.width;
        const x = data.EXPORT_EXPERIENCE * range / 100
        return coordinates.dot.x0 + x;
    }

    // calculates exact dot position on Y axis based on diagnostics result and diagram parameters
    const calculateY = (data) => {
        const range = parameters.canvas.height;
        const y = data.EXPORT_GROWTH * range / 100
        return coordinates.dot.y0 - y;
    }

    console.log("print result", result)
    console.log("print average", average)

    const style = {
        canvas: {
            margin: `20px 20px 5px 5px`,
            minWidth: `${parameters.canvas.width}px`,
            minHeight: `${parameters.canvas.height}px`,
            border: `1px solid #d6d6d6`,
        },
        dot: {
            position: `relative`,
            zIndex: 2,
            width: `${parameters.dot.width}px`,
            height: `${parameters.dot.height}px`,
            backgroundColor: `${parameters.dot.color}`,
            borderRadius: `50%`,
            left: `${calculateX(result)}px`,
            top: `${calculateY(result)}px`,
            label: {
                position: `inherit`,
                top: `10px`,
                right: `5px`,
                fontSize: `${parameters.dot.fontSize}px`,
                fontWeight: `bold`
            }
        },
        dotAvg: {
            position: `relative`,
            zIndex: 1,
            width: `${parameters.dot.width}px`,
            height: `${parameters.dot.height}px`,
            backgroundColor: `${parameters.dot.colorAvg}`,
            borderRadius: `50%`,
            left: `${calculateX(average)}px`,
            top: `${calculateY(average) - parameters.dot.height}px`,
            label: {
                position: `inherit`,
                top: result.EXPORT_EXPERIENCE >= average.EXPORT_EXPERIENCE ? `15px` : `-30px`,
                right: `19px`,
                fontSize: `${parameters.dot.fontSizeAvg}px`,
                textAlign: `center`,
                width: `50px`,
                fontWeight: `bold`,
                color: `black`
            }
        },
        row: {
            position: `relative`,
            top: `-${parameters.dot.height * 2}px`,
            height: `50%`,
        },
        quarter: {
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            width: `50%`
        },
        topLeft: {
            borderRight: axisBorderStyle,
            borderBottom: axisBorderStyle,
        },
        topRight: {
            borderLeft: axisBorderStyle,
            borderBottom: axisBorderStyle,
        },
        bottomLeft: {
            borderTop: axisBorderStyle,
            borderRight: axisBorderStyle,
        },
        bottomRight: {
            borderLeft: axisBorderStyle,
            borderTop: axisBorderStyle,
        },
        axis: {
            labels: {
                topRow: {
                    top: `-${parameters.canvas.height + parameters.dot.height * 2}px`,
                    justifyContent: `center`,
                    fontSize: `${parameters.axis.label.fontSize}px`,
                    labelAdjust: {
                        position: `relative`,
                        right: `${14}px`
                    }
                },
                centerRow: {
                    top: `-${parameters.canvas.height / 2 + parameters.dot.height + parameters.axis.label.fontSize + 4}px`,
                    fontSize: `${parameters.axis.label.fontSize}px`,
                    labelAdjust: {
                        position: `relative`,
                        right: `${6}px`
                    },
                },
                bottomRow: {
                    top: `-${parameters.dot.height * 2 + 4 * parameters.axis.label.fontSize + 4}px`,
                    justifyContent: `center`,
                    fontSize: `${parameters.axis.label.fontSize}px`,
                    labelAdjust: {
                        position: `relative`,
                        right: `${8}px`
                    }
                },
            }
        }
    }

    return (
        <div className="diagram">
            <div className="d-flex w-100 justify-content-center">
                <p className="scale-label scale-label--vertical">Pasirengimas eksporto augimui</p>
                <div style={style.canvas} className="diagram__canvas">
                    <div style={style.dot}>
                        <p style={style.dot.label}>Jūs</p>
                    </div>
                    <div style={style.dotAvg}>
                        <p style={style.dotAvg.label}>Sektoriaus vidurkis</p>
                    </div>
                    <div style={style.row} className="d-flex w-100">
                        <div style={style.topLeft} className="block__quarter">
                            <h5>Perspektyvus</h5>
                        </div>
                        <div style={style.topRight} className="block__quarter">
                            <h5>Lyderis</h5>
                        </div>
                    </div>
                    <div style={style.row} className="d-flex w-100">
                        <div style={style.bottomLeft} className="block__quarter">
                            <h5>Pradedantysis</h5>
                        </div>
                        <div style={style.bottomRight} className="block__quarter">
                            <h5>Patyręs</h5>
                        </div>
                    </div>
                    <div style={style.axis.labels.topRow} className="block__axis-labels">
                        <span style={style.axis.labels.topRow.labelAdjust}>100</span>
                    </div>
                    <div style={style.axis.labels.centerRow} className="block__axis-labels">
                        <span>0</span>
                        <span style={style.axis.labels.centerRow.labelAdjust}>50</span>
                        <span>100</span>
                    </div>
                    <div style={style.axis.labels.bottomRow} className="block__axis-labels">
                        <span style={style.axis.labels.bottomRow.labelAdjust}>0</span>
                    </div>
                </div>
            </div>
            <p className="scale-label scale-label--horizontal">Eksporto patirtis</p>
        </div>
    );
}

export default Diagram;
