import Recommendation from "./Recommendation";
import DiagnosticsHeading from "./DiagnosticsHeading";
import React from "react";

const DiagnosticRecommendationsResultBased = ({surveyDiagnostics: {detailedEstimates: scales}}) => {

    const ratingsWithRecommendations = [];

    scales.forEach(scale => {
        scale.ratingResults.forEach(rating => {
            if (rating.ratingRecommendations.length > 0) {
                ratingsWithRecommendations.push(rating)
            }
        })
    })

    const renderRatingRecommendations = (ratingResult) => {
        return <Recommendation ratingResult={ratingResult} key={ratingResult.ratingId}/>
    }

    return (
        <div className="pt-3">
            <DiagnosticsHeading text="INDIVIDUALIOS REKOMENDACIJOS LABIAUSIAI TOBULINTINOMS SRITIMS"/>
            {ratingsWithRecommendations.map(renderRatingRecommendations)}
        </div>
    )
}

export default DiagnosticRecommendationsResultBased;