import AnswersByRating from "./AnswersByRating";
import DiagnosticsHeading from "./DiagnosticsHeading";
import React from "react";

const AnswerList = ({surveyDiagnostics}) => {
    const detailedEstimates = surveyDiagnostics.detailedEstimates;
    const ratingResults = []
    detailedEstimates.forEach(estimate => {
        estimate.ratingResults.forEach(ratingResult => {
            ratingResults.push(ratingResult)
        })
    })

    const ratingAnswers = ratingResults.map(rating => {
        return {
            rating: rating.ratingName,
            answers: rating.answers
        }
    })

    ratingAnswers.push(
        {
            rating: "Be vertinimo",
            answers: surveyDiagnostics.ratinglessAnswers
        }
    )

    const renderRatingAnswers = ({rating, answers}) => <AnswersByRating key={rating} rating={rating} answers={answers}/>

    return (
        <>
            <DiagnosticsHeading text="DALYVIO ATSAKYMŲ PASIRINKIMAS"/>
            {ratingAnswers.map(renderRatingAnswers)}
        </>
        )
}

export default AnswerList;