import icon013 from "../assets/13sm.png"
import icon022 from "../assets/22sm.png";
import icon033 from "../assets/33sm.png";
import icon035 from "../assets/35sm.png"
import icon042 from "../assets/42sm.png"
import icon089 from "../assets/89sm.png";
import icon114 from "../assets/114sm.png"
import icon140 from "../assets/140sm.png"

export const benefits = {
    MATURITY_LEVEL: {
        icon: icon089,
        body:
            <span>Nustatomas jūsų įmonės <strong>brandos lygis</strong> ir eksporto procesų tobulintinos vietos.</span>
    },
    RECOMMENDATIONS: {
        icon: icon033,
        body: <span>Jūs gaunate individualiai jūsų verslui pritaikytas <strong>rekomendacijas</strong>.</span>
    },
    SERVICES_AND_TOOLS: {
        icon: icon022,
        body:
            <span>Jums pasiūlomos <strong>paslaugos ir įrankiai</strong>, padėsiantys didinti eksporto efektyvumą.</span>
    }
}

export const criteria = {
    BASIC_READINESS: {
        icon: icon089,
        title: `Bazinis įmonės pasiruošimas`,
        body: `Greitą eksporto sėkmę patiria ir labai jaunos įmonės. Tačiau eksporto augimo tvarumą užtikrina įmonės branda, akcininkų ir vadovų parama, išbandytas verslo modelis.`,
    },
    MARKET_KNOWLEDGE: {
        icon: icon035,
        title: `Tikslinių rinkų ir segmentų pažinimas`,
        body: `Gerai atlikta analizė ir praktiškai išbandytos užsienio rinkos padeda efektyviai panaudoti įmonės eksporto resursus ir naudoti eksporto plėtros priemones ten, kur atsipirkimas didžiausias.`,
    },
    PRODUCT_READINESS: {
        icon: icon042,
        title: `Produktų ar paslaugų paruošimas eksportui`,
        body: `Kiekvienos naujos rinkos iššūkiai skirtingi, tad plėtrai užsienyje reikia ruoštis papildomai: atnaujinti kainodaros ir pozicionavimo strategijas, sertifikatus, adaptuoti gamybos procesus.`,
    },
    MARKETING: {
        icon: icon140,
        title: `Rinkodara ir viešieji ryšiai`,
        body: `Vietiniams suprantama ir patraukli komunikacija, reprezentatyvus įvaizdis internete – tai reikšmingi elementai siekiant įsitvirtinti užsienio rinkose.`,
    },
    SALES_MANAGEMENT: {
        icon: icon013,
        title: `Eksporto pardavimų proceso valdymas ir įgūdžiai`,
        body: `Ilgalaikis eksporto augimas pasiekiamas, kai įmonės darbuotojai dirba nuosekliai ir profesionaliai, pasitelkdami veiksmingiausias pardavimų metodikas ir technologinius įrankius.`,
    },
    WILL_TO_EXPAND: {
        icon: icon114,
        title: `Pasiryžimas plėsti eksportą ir veiksmų planavimas`,
        body: `Teorinis pasiruošimas eksportuoti yra viena, o aiškaus veiksmų plano turėjimas ir nuoseklus jo įgyvendinimas – kas kita. Ar jau nustatėte eksporto tikslus, biudžetą, priemones kitiems metams?`,
    }
}