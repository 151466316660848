import "../../styles/menu.css"
import React from "react";
import {useLocation} from "react-router-dom";
import logoImg from "./ia_logo.png";


const Menu = () => {
    const location = useLocation();

    const isCmsPath = location.pathname.indexOf("cms") >= 0;
    const isResultPath = location.pathname.indexOf("ivertinimas") >= 0;

    const Logo = () => {
        return <img className="d-flex header__logo" src={logoImg} alt="Inovacijų Agentūra Logotipas"/>
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    const logoHref = isResultPath ? location.pathname : "/"

    return (
        <header className="header">
            <div className="container-fluid col-xl-7">
                <div className="d-flex justify-content-between align-items-center">
                    <a href={logoHref}><Logo/></a>
                    <div className="w-100 mb-0 mt-3 ml-2 d-flex flex-column">
                        <h1 className="d-flex">Eksporto diagnostikos testas</h1>
                        {isCmsPath &&
                        <nav className="m-1 d-flex">
                            <a className="header__link ml-0" href="/cms">Apklausos</a>
                            <a className="header__link" href="/cms/rating">Vertinimai</a>
                            <a className="header__link" href="/cms/companies">Įmonės</a>
                            <a className="header__link" href="/cms/statistics">Įmonių statistika</a>
                            <a className="header__link" href="/cms/questions-all">Klausimų statistika</a>
                            <a className="header__link" href="/cms/recommendations">Bendros rekomendacijos</a>
                            <a className="header__link header__link--highlighted" href="/login" onClick={logout}>Atsijungti</a>
                        </nav>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Menu;