import {ProgressBar, Step} from "react-step-progress-bar";
import ProgressBubble from "../../components/progress/ProgressBubble";
import {getQuestionnaireIndexByName} from "../../api/utils";

const ProgressBarQuestionnaires = ({currentQuestionnaireName, allQuestionnaires}) => {

    const currentQuestionnaireIndex = getQuestionnaireIndexByName(
        allQuestionnaires,
        currentQuestionnaireName
    )

    const totalSteps = allQuestionnaires.length - 1;
    const percentPerStep = 100 / totalSteps;


    const calculatePercentage = () => {
        // this conditional ensures 1 step delay from library defaults and prevents from negative percentage
        // for the desired functionality - to indicate: bubble in progress, bubbles completed & bubbles not-completed
        // the "accomplished" state lights up only when all questions in certain questionnaire were answered
        // for this to work - step at index zero must also light up only when fulfilled
        // to avoid library defaults, where the first step is always lit up even if percentage = 0
        // the bubble accomplished style is overriden with "unaccomplished-but-active" styling
        return currentQuestionnaireIndex > 0 ? (currentQuestionnaireIndex) * percentPerStep : 0;
    }

    const isInProgress = (index) => {
        return index === currentQuestionnaireIndex;
    }

    const generateSteps = () => {
        return allQuestionnaires.map(questionnaire => {
            return (
                <Step transition="scale" key={`step/${questionnaire.id}`}>
                    {({accomplished, index}) => {
                        return (
                            <ProgressBubble
                                index={index}
                                activeIndex={currentQuestionnaireIndex}
                                description={questionnaire.name}
                                isAccomplished={accomplished}
                                isInProgress={isInProgress(index)}
                            />

                        )
                    }}
                </Step>
            )
        });
    }

    return (
        <div className="container-fluid mt-5">
            <ProgressBar
                percent={calculatePercentage()}
                height={5}
                filledBackground="var(--dark-blue)"
                children={generateSteps()}
                hasStepZero
            >
            </ProgressBar>
        </div>
    )
}

export default ProgressBarQuestionnaires;