import React from "react";
import "../../../styles/table.css"

const CompaniesTable = ({data, page, sizePerPage, onTableChange, totalSize, sendInvite, handleDelete, titleTable}) => (
    <div className="wrapperTable">
        <div className='boxTable'>
            <div className='tableHeader'>
                {titleTable?.map(i => (
                    <div className='tableHeaderItem' key={i}>{i}</div>
                ))}
            </div>
            {data?.map(i => (
                <div className='tableBody'>
                    <div className='bodyItem bodyItemBendrove'>{i?.attendee?.companyName}</div>
                    <div className='bodyItem bodySector'>{i?.sector}</div>
                    <div className='bodyItem bodyBazinės'>{i?.[5]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyTikslinių'>{i?.[6]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyProduktų'>{i?.[7]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyRinkodara'>{i?.[8]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyEksporto'>{i?.[9]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyPlėsti'>{i?.[10]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyKanalų'>{i?.[3]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyDalis'>{i?.[0]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyIstorija'>{i?.[1]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodygeografija'>{i?.[2]?.ratingTotalEstimate}</div>
                    <div className='bodyItem bodyApimtys'>{i?.[4]?.ratingTotalEstimate}</div>
                </div>
            ))}
        </div>
    </div>
);

export default CompaniesTable;
