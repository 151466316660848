import FormHeading from "../../components/formik/elements/FormHeading";
import {ErrorMessage, Formik} from "formik";
import React, {useState} from "react";
import {registerPublicAttendee} from "../../api/attendeeApiPublic";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import FormButton from "../../components/formik/elements/Button";
import SuccessSubmit from './SuccessModal';

const PublicAttendeeRegisterForm = () => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const handleRegister = (values, setSubmitting, resetForm) => {
        setSubmitError('');
        registerPublicAttendee(values).then(()=>{
            resetForm({values: {isExport: false, companyName: "", email: ""}});
            setShowSuccessModal(true);
        }).catch(
            (error)=>{
                setSubmitError('There is some server error')
                resetForm({values: {isExport: "", companyName: "", email: ""}});
                setShowSuccessModal(true);
                console.error(error)
            }
        )
    }

    const handleCloseSuccess = () => {
        setShowSuccessModal(false);
        window.location.href = 'https://kc.inovacijuagentura.lt/eksportuok/eksportuok/eksporto-diagnostika.html?lang=lt';
    }

    const newCompanyValidationSchema = Yup.object().shape({
        isExport: Yup.boolean().required('Tai būtinas klausimas'),
        companyName: Yup.string().required("Būtina nurodyti pavadinimą"),
        email: Yup.string()
            .email("Netinkamas el. pašto adreso formatas")
            .required("Būtina nurodyti el. paštą"),
    });
    return (
        <div className="container-fluid col-xl-7 mb-4 pt-4">
            <FormHeading text="REGISTRACIJA EKSPORTO DIAGNOSTIKOS TESTUI "/>
            <p>
                Maloniai prašome atsakyti į kelis registracijos klausimus, po kurių į savo el. paštą gausite prisijungimus prie eksporto diagnostikos testo.
                Daugiau informacijos apie Inovacijų agentūros privatumo politiką:
                <a
                    className="form__link ml-1"
                    href={`https://kc.inovacijuagentura.lt/turinys/privatumo-politika.html`}>
                    https://kc.inovacijuagentura.lt/turinys/privatumo-politika.html
                </a>
            </p>
            <Formik
                initialValues={{isExport: false, companyName: "", email: ""}}
                validationSchema={newCompanyValidationSchema}
                onSubmit={({...values}, {
                    setSubmitting,
                    resetForm
                }) => handleRegister(values, setSubmitting, resetForm)}>
                {(formik) =>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit(e)
                    }}>
                        <Form.Label className="mt-3">1. Ar Jūsų įmonė vykdo eksportą?</Form.Label>
                        <Form.Check
                            type="radio"
                            name="isExport"
                            label="Taip"
                            defaultValue={formik.values.isExport}
                            value={true}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <Form.Check
                            type="radio"
                            name="isExport"
                            label="Ne"
                            defaultValue={formik.values.isExport}
                            value={false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <ErrorMessage name="isExport" component="small" className="form__validation-error"/>
                        <Form.Label className="mt-3">2. Įmonės pavadinimas</Form.Label>
                        <Form.Control
                            type="text"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <ErrorMessage name="companyName" component="small" className="form__validation-error"/>
                        <Form.Label className="mt-3">3. El. paštas:</Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <ErrorMessage name="email" component="small" className="form__validation-error"/>
                        <div className="mt-3">
                            <FormButton type="submit" text="Registruoti"/>
                        </div>
                        {
                            !!submitError ?
                            (<div className="form__validation-error">{submitError}</div>)
                                : ''
                        }
                    </Form>
                }
            </Formik>
            <SuccessSubmit
                showConfirmationModal={showSuccessModal}
                handleClose={handleCloseSuccess}
            />
        </div>
    )
}

export default PublicAttendeeRegisterForm;