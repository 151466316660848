import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Spinner, Table} from "react-bootstrap";
import questionApi from "../../api/questionApi";
import FormButton from "../../components/formik/elements/Button";
import TwoButtonModal from "../../components/modals/TwoButtonModal";
import FormHeading from "../../components/formik/elements/FormHeading";

export default ({match}) => {

    let history = useHistory();

    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalDeleteBody, setModalDeleteBody] = useState("");
    const [questionForDeletion, setQuestionForDeletion] = useState();

    useEffect(() => {
        questionApi.fetchQuestionnaireQuestions(match.params.surveyId, match.params.questionnaireId)
            .then(response => setQuestions(response.data.questions))
            .finally(() => setIsLoading(false));
    }, [])

    function deleteQuestion(question) {
      setQuestionForDeletion(question);
      setModalDeleteBody(`Ar tikrai norite ištrinti "${question.name}" klausimą?`);
      setShowDeleteModal(true);
    }

    function closeDeleteModal() {
      setQuestionForDeletion(null);
      setShowDeleteModal(false);
    }

    function actionDeleteModal(id) {
      setIsLoading(true);
      questionApi.deleteQuestionnaireQuestion(match.params.surveyId, match.params.questionnaireId, id)
        .then(response => {
            setQuestionForDeletion(null);
            history.go(0);
          }
        )
        .finally(() => {
            setShowDeleteModal(false);
            setIsLoading(false);
        });
    }

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Klausimų sąrašas"/>
            {isLoading && <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
            <Link to={`/cms/survey/1/questionnaire/${match.params.questionnaireId}/question/new`}>
                <FormButton text="Naujas klausimas" />
            </Link>
            {questions.length > 0 && <Table striped bordered hover className="mt-3" size="sm">
                <thead>
                <tr>
                    <th colSpan="3">Klausimo pavadinimas</th>
                </tr>
                </thead>
                <tbody>
                {questions.map(q => (
                    <tr key={q.id}>
                        <td className="w-100">{q.name}</td>
                        <td>
                            <Link to={`/cms/survey/1/questionnaire/${match.params.questionnaireId}/question/${q.id}/edit`}>
                                <FormButton isForTable text="Redaguoti" />
                            </Link>
                        </td>
                        <td>
                          <FormButton
                              isForTable
                              text="Ištrinti"
                              type="button"
                              onClick={() => {deleteQuestion(q)}}
                          />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>}
            <TwoButtonModal
              show={showDeleteModal}
              title="Trinimo patvirtinimas"
              body={modalDeleteBody}
              handleClose={closeDeleteModal}
              closeButtonTitle="Atšaukti"
              handleAction={() => {actionDeleteModal(questionForDeletion.id)}}
              actionButtonTitle="Trinti"
            />
        </div>
    )
}