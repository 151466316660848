import DiagnosticsHeading from "./DiagnosticsHeading";
import React from "react";
import Scale from "./Scale";

const DiagnosticScaleDetails = ({surveyDiagnostics:{detailedEstimates:scaleList}, isDetail, sectorAverage}) => {
    return (
        <div className="pt-1">
            <DiagnosticsHeading text={isDetail ? "IŠ KO SUSIDEDA ĮVERTINIMAS" : "IŠ KO SUSIDEDA JŪSŲ ĮVERTINIMAS?"}/>
            {
                scaleList.map((scaleDetails) => (
                <Scale key={scaleDetails.scale} details={scaleDetails} sectorAverage={sectorAverage}/>
                ))
            }
        </div>
        )
}

export default DiagnosticScaleDetails;