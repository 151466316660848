import {Form} from "react-bootstrap";
import React from "react";

const QuestionOptionRecommendation = ({formik, optionIndex}) => {

    const isSlider = formik.values.type === "SLIDER";
    const intervalCount = 4;
    const intervals = [];
    for (let i = 0; i < intervalCount; i++) {
        const interval = {
            no: i + 1,
            percentageMin: Math.round((1 / intervalCount * 100 * i)),
            percentageMax: Math.round((1 / intervalCount * 100 * (i + 1)))
        }
        intervals.push(interval);
    }

    const {values: {questionOptions: options}} = formik;
    const option = options[optionIndex];
    const {recommendations} = option;
    const recommendationsFieldName = `questionOptions[${optionIndex}].recommendations`

    const clearRecommendations = () => {
        if (recommendations) {
            recommendations.length = 0;
        }
    }

    const getCurrentRecommendation = (interval) => {
        const recommendations = formik.values.questionOptions[optionIndex].recommendations;
        return interval
            ? recommendations.find(rec => rec.intervalNo === interval.no.toString())
            : recommendations[0];
    }

    const showTriggerSelect = (interval) => {
        return (
            <div key={(interval?.no + "/" + intervalCount + "trigger") || "trigger"}
                 className="ml-3 mt-2">
                <Form.Check
                    label="Kai pasirinktas"
                    type="radio"
                    name={interval?.no || recommendationsFieldName}
                    value={"PICKED"}
                    onChange={handleTriggerSelect}
                    onBlur={formik.handleBlur}
                    defaultChecked={getCurrentRecommendation(interval)?.triggerCondition==="PICKED"}
                />
                <Form.Check
                    label="Kai nepasirinktas"
                    type="radio"
                    name={interval?.no || recommendationsFieldName}
                    value={"NOT_PICKED"}
                    onChange={handleTriggerSelect}
                    onBlur={formik.handleBlur}
                    defaultChecked={getCurrentRecommendation(interval)?.triggerCondition==="NOT_PICKED"}
                />
            </div>
        )
    }

    const showTextArea = (interval) => {
        const index = isSlider ? recommendations.map(rec => rec.intervalNo).indexOf(interval.no.toString()) : 0;
        const textAreaName = `questionOptions[${optionIndex}].recommendations[${index}].message`;
        return (
            <div className="ml-5">
                <label>Rekomendacija:</label>
                <Form.Control
                    key={(interval?.no + "/" + intervalCount + "message") || "message"}
                    as="textarea"
                    rows={3}
                    name={textAreaName}
                    value={formik.values.questionOptions[optionIndex].recommendations[index].message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </div>
        )
    }

    const showIntervalSelect = () => {
        return (
            <div>
                {intervals.map(renderInterval)}
            </div>
        )
    }

    const renderInterval = (interval) => {
        return (
            <div className="d-flex flex-row mt-3 ml-4 p-2">
                <div key={interval.no + "/" + intervalCount} >
                    <Form.Check
                        label={`Intervalas ${interval.percentageMin}-${interval.percentageMax} %`}
                        type="checkbox"
                        name={recommendationsFieldName}
                        value={interval.no}
                        onChange={handleIntervalSelect}
                        onBlur={formik.handleBlur}
                        defaultChecked={getCurrentRecommendation(interval)}
                    />
                    {/* ask for trigger condition (picked/not_picked) */}
                    {(recommendations.map(rec => rec.intervalNo).includes(interval.no.toString()) || getCurrentRecommendation(interval)) && showTriggerSelect(interval)}
                </div>
                {/* when trigger condition selected show text area */}
                {recommendations.find(rec => rec.intervalNo === interval.no.toString())?.triggerCondition && showTextArea(interval)}

            </div>
        )
    }

    const handleIntervalSelect = (e) => {
        const intervalNo = e.target.value;
        const isChecked = e.target.checked;
        if (!isChecked) {
            const withoutUnchecked = recommendations.filter(rec => rec.intervalNo !== intervalNo);
            formik.setFieldValue(recommendationsFieldName, withoutUnchecked)
        } else {
            formik.setFieldValue(recommendationsFieldName, [...recommendations, {
                intervalNo,
                intervalCount
            }])
        }
    }

    const handleTriggerSelect = (e) => {
        const intervalNo = isSlider ? e.target.name : null;
        const triggerCondition = e.target.value;
        if (isSlider) {
            recommendations.find(rec => rec.intervalNo === intervalNo).triggerCondition = triggerCondition
            // sets as touched in order to render textArea immediately after select rather than wait for blur event
            formik.setFieldTouched(recommendationsFieldName, true)
        } else {
            const message = recommendations[0]?.message ? recommendations[0].message : "";
            clearRecommendations();
            recommendations.push({triggerCondition, message});
            // sets as touched in order to render textArea immediately after select rather than wait for blur event
            formik.setFieldTouched(recommendationsFieldName, true)
        }
    }

    return (
        <div>
            {/* when unchecked - clear recommendations */}
            {!option.hasRecommendations && clearRecommendations()}

            <div className="d-flex mt-2 ml-2">
                {/* when checked ask for trigger condition (picked/not_picked) */}
                {!isSlider && option.hasRecommendations && showTriggerSelect()}

                {/* when trigger condition selected show text area */}
                {!isSlider && recommendations?.length > 0 && showTextArea()}
            </div>

            {/* when slider - list intervals and repeat above for each */}
            {isSlider && option.hasRecommendations && showIntervalSelect()}
        </div>
    )
}

export default QuestionOptionRecommendation;