import cx from "classnames"
import React from "react";
import newWindow from "../img/new-window-icon3.png";

const StatusTag = ({status, attendeeCode, hasError}) => {

    const resolveStatus = (status) => {
        switch (status) {
            case "REGISTERED":
                return "UŽREGISTRUOTA"
            case "STARTED":
                return "TESTAS PILDOMAS"
            case "FINISHED":
                return "TESTAS BAIGTAS"
            default:
                return "NEŽINOMA BŪSENA"
        }
    }

    return (
        <div className={cx({
            "status": true,
            "d-flex": true,
            "justify-content-center": true,
            "status--finished": status === "FINISHED" && !hasError,
            "status--error": hasError
        })}>
            {
                hasError
                    ? <span>{resolveStatus(status)}</span>
                    : <>
                        <a href={`/apklausa/${attendeeCode}`} target="_blank">{resolveStatus(status)}</a>
                        <img className="ml-2" src={newWindow} alt={"new window icon"}/>
                    </>
            }

        </div>
    )
}

export default StatusTag;