import React from 'react';
import QuestionOption from './QuestionOption';
import FormButton from "../../components/formik/elements/Button"
import Checkbox from "../../components/formik/elements/Checkbox";

export default({formik}) => {

  const {values, setValues} = formik;

  function addQuestionOption() {
    const questionOptions = [...values.questionOptions];
    questionOptions.push(
      {
        value: '',
        weight: undefined,
        minValue: undefined,
          hasRecommendations: false,
          recommendations: []
      }
    );
    setValues({
      ...values,
      questionOptions
    });
  }

  return (
    <div className="mt-3">
      Atsakymo variantai:
      <div className="p-2 bg-light">
        { values.questionOptions.map((radio, index) => (
              <QuestionOption formik={formik} index={index} key={index} />
        ))}

        <FormButton
                text="Pridėti atsakymo variantą"
                type="button"
                onClick={addQuestionOption}>
        </FormButton>
      </div>
    </div>
  );
}