import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Spinner, Form} from "react-bootstrap";
import FormButton from "../../components/formik/elements/Button"
import {Formik} from "formik";
import * as Yup from 'yup';
import surveyApi from "../../api/surveyApi";
import questionApi from "../../api/questionApi";
import Attendee from "../answer/Attendee";
import Answer from "../answer/Answer";
import CountrySelect from "../answer/CountrySelect";
import ProgressBar from "../../components/progress/ProgressBar";
import ProgressBarQuestionnaires from "../../components/progress/ProgressBarQuestionnaires";
import {getQuestionnaireIndexByName} from "../../api/utils";
import QuestionnaireHeading from "../../components/formik/elements/QuestionnaireHeading";
import LandingPage from "../landing/LandingPage";
import {fetchAttendee} from "../../api/attendeeApi";
import SurveyAttemptDenied from "./SurveyAttemptDenied";
import ErrorAlert from "../error/ErrorAlert";

const SurveyAttempt = ({match, surveyId}) => {

    const localSurveyId = surveyId ? surveyId : match.params.surveyId;
    const invitedAttendeeCode = match.params.attendee;

    let history = useHistory();
    const [currentIndex, setCurrentIndex] = useState(0);
    const nextIndex = currentIndex + 1;
    const previousIndex = currentIndex - 1;
    const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [questionHistory, setQuestionHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [hasAtendeeInfo, setHasAtendeeInfo] = useState(false);
    const [progressCompleted, setProgressCompleted] = useState(0);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [invitedAttendee, setInvitedAttendee] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        setIsLoading(true)
        questionApi.fetchSurveyQuestionsCount(localSurveyId)
            .then(({data}) => setTotalQuestionsCount(data))
            .finally(() => setIsLoading(false));
    }, [localSurveyId]);
    useEffect(() => {
        setIsLoading(true)
        questionApi.fetchSurveyFirstQuestion(localSurveyId)
            .then(({data}) => {
                setCurrentQuestion(data)
            })
            .finally(() => setIsLoading(false));
    }, [localSurveyId]);
    useEffect(() => {
        setIsLoading(true)
        surveyApi.fetchSurveyQuestionnaires(localSurveyId)
            .then(({data}) => setQuestionnaires(data))
            .finally(() => setIsLoading(false));
    }, [localSurveyId])


    // ATTENDEE INVITE LOGIC
    useEffect(() => {
        if (invitedAttendeeCode) {
            setIsLoading(true);
            fetchAttendee(invitedAttendeeCode)
                .then(({data}) => handleAttendeeFound(data))
                .catch(err => {
                    if (err.status === 404) {
                        history.push(`/apklausa`)
                    } else {
                        setError("Įvyko nenumatyta klaida.")
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }, [invitedAttendeeCode])

    const handleAttendeeFound = (attendee) => {
        switch (attendee.status) {
            case "REGISTERED": setInvitedAttendee(attendee)
                break;
            case "STARTED": setInvitedAttendee(attendee)
                break;
            case "FINISHED": history.push(`/apklausa/${attendee.attendeeCode}/ivertinimas`)
                break;
            default: setError("Klaida: nežinomas apklausos dalyvio statusas.")
        }
    }

    const initialState = {
        answers: [
            {
                id: null,
                value: '',
            }
        ],
        attendee: {
            companyName: invitedAttendee && invitedAttendee.companyName,
            companyWebsite: '',
            attendeeName: '',
            email: invitedAttendee && invitedAttendee.email,
            phoneNo: '',
            policyAgree: false,
            recommendationsAgree: false,
            attendeeCode: invitedAttendee && invitedAttendee.attendeeCode,
        },
    }

    const validationSchema = Yup.object().shape({
        attendee: Yup.object().shape({
            companyName: Yup.string().required('Įmonės pavadinimas yra privalomas'),
            attendeeName: Yup.string().required('Vardas/pavardė yra privalomi'),
            email: Yup.string().email('Netinkamas el. pašto formatas').required('El. paštas yra privalomas'),
            policyAgree: Yup.bool().oneOf([true], 'Būtina pažymėti'),
        }),
        country: Yup.object().test('is-country-selected', 'Būtina pasirinkti', (value, label) => (value && label)),
    });

    function updateProgress(question) {
        const stepsBeforeQuestions = 2;
        const maxSteps = totalQuestionsCount + stepsBeforeQuestions;
        const defaultIncrement = 100 / maxSteps;
        const stepNo = question.questionsRemaining ? maxSteps - question.questionsRemaining : question;
        setProgressCompleted(Math.round(stepNo * defaultIncrement));
    }

    const hasStartedQuestions = currentIndex > 1;
    const isAtFirstQuestion = currentIndex < 3;
    const hasReachedFinalQuestion = currentIndex === totalQuestionsCount + 1;
    const hasNotFinishedQuestions = currentIndex < totalQuestionsCount + 2;

    function nextQuestion(formik) {
        const answers = formik.values.answers;
        setIsValidating(true);
        formik.validateForm().then((errors) => {
            setIsValidating(false);

            if (currentIndex > 0) {
                //triggers formik meta update for pre-submit error display
                formik.setTouched({
                    attendee: {
                        companyName: true,
                        attendeeName: true,
                        email: true,
                        policyAgree: true,
                        recommendationsAgree: true
                    }
                });
            }

            if ((currentIndex === 1 && errors.attendee)
                || (currentIndex > 1 && errors.answers)) {
                return false;
            }

            if ((currentIndex === 1 && !hasAtendeeInfo)) {
                setHasAtendeeInfo(true);
                updateProgress(1);
                return true;
            }

            if (currentIndex < 2 && hasAtendeeInfo) {
                setCurrentIndex(currentIndex + 1);
                updateProgress(2);
                return true;
            }

            if (currentIndex < 2) {
                // info shown, attendee entered, so go to first question
                setCurrentIndex(currentIndex + 1);
                updateProgress(0);
                return true;
            }
            // go to next questions or submit if last one
            if (hasNotFinishedQuestions) {
                setIsLoadingQuestion(true)
                questionApi.fetchSurveyNextQuestion(localSurveyId, syncToCurrentPointInHistory(formik.values))
                    .then(response => {
                        const suggestedNextQuestion = response.data;

                        // validations to ensure proper question history flow
                        if (suggestedNextQuestion) {
                            if (wasNextQuestionPreviously(suggestedNextQuestion)) {
                                mountNextQuestionFromHistory()
                            } else {
                                if (hasDiscoveredNewConditionalQuestion(suggestedNextQuestion, answers)) {
                                    injectQuestionToHistory(suggestedNextQuestion, answers)
                                }
                                if (hasSkippedConditionalQuestions(suggestedNextQuestion)) {
                                    forgetSkippedQuestions(suggestedNextQuestion, answers);
                                }
                                advanceWithQuestion(suggestedNextQuestion)
                            }

                        } else {
                            // reached the end
                            setCurrentIndex(totalQuestionsCount + 2);
                            formik.submitForm();
                        }
                    })
                    .finally(() => setIsLoadingQuestion(false));
                return false;
            } else {
                //
            }
        });
    }

    // when attempting to advance within question history
    // ensures that only answers up to this point are considered for next-question-check
    // because otherwise API would check against full answer bundle
    // since Formik contains all answered values, regardless of history point that user is currently in
    const syncToCurrentPointInHistory = (fullRequest) => {
        const slicedRequest = {...fullRequest}
        slicedRequest.answers = slicedRequest.answers.slice(0, currentIndex + 1)
        return slicedRequest;
    }

    const advanceWithQuestion = (question) => {
        setCurrentQuestion(question);
        setCurrentIndex(nextIndex);
        updateProgress(question);
    }

    // when iterating through question history, answer changes may impact future question visibility
    // e.g. a changed answer may trigger some new question to be shown/hidden in place where it was/wasn't previously
    // this function checks if passed to-be-next question has also been next previously according to question history
    const wasNextQuestionPreviously = (nextQuestion) => {
        const nextQuestionPreviously = questionHistory
            .find(pq => pq.answerIndex === nextIndex)?.question;
        return nextQuestionPreviously && nextQuestion.id === nextQuestionPreviously.id;
    }

    // this function advances with a next, already answered question from question history
    // rather than advancing with a fetched one from API
    const mountNextQuestionFromHistory = () => {
        const nextQuestionFromHistory = questionHistory
            .find(q => q.answerIndex === nextIndex).question
        advanceWithQuestion(nextQuestionFromHistory)
    }

    // saves current question to question history if not already saved
    //  (by checking if that slot (historyPoint) is available)
    const saveCurrentQuestionToHistory = () => {
        if (!questionHistory
            .map(historyPoint => historyPoint.answerIndex)
            .includes(currentIndex)) {
            setQuestionHistory(prevState => [...prevState, {answerIndex: currentIndex, question: currentQuestion}]);
        }
    }

    // in case after changing the answers - suggested next question is different from what was there before
    // this function evaluates if this unexpected question is a fresh new question (rather than skipped to)
    const hasDiscoveredNewConditionalQuestion = (suggestedNextQuestion, answers) => {
        const questionAtNextIndexBefore = questionHistory
            .find(historyPoint => historyPoint.answerIndex === nextIndex) || answers[nextIndex];
        return (questionAtNextIndexBefore)
            && !hasSkippedConditionalQuestions(suggestedNextQuestion)
            && suggestedNextQuestion.id !== questionAtNextIndexBefore.id;
    }

    // in case after changing the answers - suggested next question is different from what was there before
    // this function evaluates if this unexpected question is a result of skipped questions (rather than being fresh new)
    const hasSkippedConditionalQuestions = (suggestedNextQuestion) => {
        const questionEntryInHistory = questionHistory
            .find(historyPoint => historyPoint.question.id === suggestedNextQuestion.id)
        return questionEntryInHistory && nextIndex !== questionEntryInHistory?.answerIndex;
    }

    // if changing answers resulted some questions to be skipped
    // this function removes them from history and rearranges indexes
    const forgetSkippedQuestions = (suggestedNextQuestion, answers) => {
        const indexPreviously = questionHistory
            .find(historyPoint => historyPoint.question.id === suggestedNextQuestion.id).answerIndex;
        const indexesToForget = questionHistory
            .filter(historyPoint => (historyPoint.answerIndex >= nextIndex) && (historyPoint.answerIndex < indexPreviously))
            .map(historyPoint => historyPoint.answerIndex);
        const updatedHistory = questionHistory.filter(historyPoint => !indexesToForget.includes(historyPoint.answerIndex))
        updatedHistory.forEach(historyPoint => {
            // shift question indexes left to take place of removed ones
            if (historyPoint.answerIndex > indexesToForget[0]) {
                historyPoint.answerIndex = historyPoint.answerIndex - indexesToForget.length;
            }
        })
        setQuestionHistory(updatedHistory)
        answers.splice(nextIndex, indexesToForget.length)
    }

    // if changing answers resulted a new question to emerge WITHIN history,
    // this function embeds it at that exact point
    const injectQuestionToHistory = (question, answers) => {
        const currentHistory = [...questionHistory]
        currentHistory.forEach(historyPoint => {
            if (historyPoint.answerIndex >= nextIndex) {
                historyPoint.answerIndex = historyPoint.answerIndex + 1
            }
        });
        setQuestionHistory([...currentHistory, {answerIndex: nextIndex, question: question}]);
        answers.splice(nextIndex, 0, {id: undefined})
    }

    const previousQuestion = () => {
        const previousQuestion = questionHistory.find(q => q.answerIndex === previousIndex).question
        setCurrentIndex(previousIndex)
        setCurrentQuestion(previousQuestion);
    }

    const isNotAnswered = (formik) => {
        return !formik.values.answers[currentIndex]?.id
            || formik.values.answers[currentIndex]?.id.length === 0
    }

    function handleSubmit(values) {
        values.attendee.targetCountry = values.country.label;
        surveyApi.storeNewSurveyAttempt(values, localSurveyId)
            .then((response) => {
                const saUuid = response.data;
                history.push(`/apklausa/${invitedAttendeeCode}/ivertinimas`);
            })
    }

    const getQuestionnaireName = () => {
        return currentQuestion.questionnaireName;
    }

    const getQuestionnaireNumber = () => {
        return getQuestionnaireIndexByName(questionnaires, getQuestionnaireName()) + 1;
    }

    return (
        <div className="container-fluid col-xl-7 mb-4">
            {isLoading &&
            <Spinner animation="border" role="status" className="m-5">
                <span className="sr-only">Loading...</span>
            </Spinner>}
            {error && <ErrorAlert error={error}/>}
            {!error && !isLoading &&
            <div>
                {
                    currentIndex > 0
                    && <>
                        <ProgressBar completed={progressCompleted}/>
                        <ProgressBarQuestionnaires
                            currentQuestionnaireName={currentQuestion.questionnaireName}
                            allQuestionnaires={questionnaires}/>
                    </>
                }
                {invitedAttendee
                    ?
                    <Formik
                        initialValues={initialState}
                        validationSchema={validationSchema}
                        onSubmit={values => handleSubmit(values)}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                {currentIndex === 0 &&
                                <div>
                                    <LandingPage onStart={() => nextQuestion(formik)}/>
                                </div>
                                }
                                {currentIndex === 1 && !hasAtendeeInfo &&
                                <div>
                                    <Attendee formik={formik} isValidating={isValidating}/>
                                    <FormButton type="button" text="Tęsti" onClick={() => nextQuestion(formik)}/>
                                </div>
                                }

                                {currentIndex === 1 && hasAtendeeInfo &&
                                <CountrySelect formik={formik} handleClick={() => nextQuestion(formik)}/>
                                }

                                {hasStartedQuestions && hasNotFinishedQuestions &&
                                <div className="progress__content-offset">
                                    <QuestionnaireHeading
                                        prefix={`TEMA #${getQuestionnaireNumber()}:`}
                                        text={`${getQuestionnaireName()}`}
                                    />
                                    <Answer formik={formik}
                                            question={currentQuestion}
                                            questionnaireId="1"
                                            index={currentIndex}
                                            saveQuestionToHistory={saveCurrentQuestionToHistory}
                                    />

                                    {hasStartedQuestions && hasNotFinishedQuestions &&
                                    <FormButton text="Grįžti"
                                                onClick={() => previousQuestion()}
                                                disabled={isAtFirstQuestion}
                                                type="button"
                                                mr
                                    />
                                    }

                                    {hasReachedFinalQuestion ?
                                        <FormButton text="Išsaugoti"
                                                    onClick={() => nextQuestion(formik)}
                                                    disabled={formik.isSubmitting}
                                                    type="button"
                                                    mr
                                        />
                                        :
                                        <FormButton text="Tęsti"
                                                    onClick={() => nextQuestion(formik)}
                                                    disabled={isNotAnswered(formik) || hasReachedFinalQuestion}
                                                    type="button"
                                        />
                                    }
                                    {isLoadingQuestion &&
                                    <Spinner animation="border" role="status" className="ml-3">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>}
                                </div>
                                }
                            </Form>
                        )}
                    </Formik>
                    : <SurveyAttemptDenied/>
                }
            </div>
            }
        </div>
    );
}

export default SurveyAttempt;