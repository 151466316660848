import React, {useState, useEffect} from 'react';
import questionApi from '../../../api/questionApi';
import ListContentModal from '../../../components/modals/ListContentModal';
import QuestionShow from './QuestionShow';
import FormButton from '../../../components/formik/elements/Button';

const QuestionShows = ({formik, surveyId}) => {

  const {values} = formik;

  const [allQuestions, setAllQuestions] = useState([]);
  const [isShowQuestionsModal, setIsShowQuestionsModal] = useState(false);

  useEffect(() => {
    if (!values.conditionalShow) {
      formik.setValues({
        ...values,
        questionShows: []
      });
    }
  }, [values.conditionalShow])

  function showQuestionsModal() {
    if (allQuestions == null || allQuestions.length === 0) {
      questionApi.fetchSurveyQuestions(surveyId)
          .then(response => {
            setAllQuestions(response.data);
            setIsShowQuestionsModal(true);
          })
          .finally();
    }
    else {
      setIsShowQuestionsModal(true);
    }
  }

  function closeQuestionsModal() {
    setIsShowQuestionsModal(false);
  }

  function actionQuestionsModal(chosenQuestion) {
    questionApi.fetchSurveyCertainQuestion(surveyId, chosenQuestion.id)
        .then(response => {
          const questionFull = response.data;
          let newCondition = {
             conditionType: 'AND',
             name: questionFull.name,
             type: questionFull.type,
             questionOptions: [...questionFull.questionOptions],
             questionShowConditions: []
           };

          let oldConditions = [...values.questionShows];
          oldConditions.push(newCondition);
          formik.setValues({
            ...values,
            questionShows: oldConditions
          });
        })
        .finally(() => setIsShowQuestionsModal(false));
  }

  return (
    <div>
      {values.conditionalShow && (
        <div>
          <p className="mt-3">Rodyti jeigu:</p>
            {values.questionShows.map((condition, index) => (
              <QuestionShow
                condition={condition}
                formik={formik}
                index={index}
                key={index}
              />
            ))}
            <FormButton
              text="Pridėti klausimo sąlygą"
              type="button"
              onClick={showQuestionsModal}
              disabled={isShowQuestionsModal} />
          </div>
      )}
      <ListContentModal show={isShowQuestionsModal}
                        title="Pasirinkite klausimą"
                        bodyObject={allQuestions}
                        handleClose={closeQuestionsModal}
                        closeButtonTitle="Uždaryti"
                        handleAction={actionQuestionsModal} />
    </div>
  );
}

export default QuestionShows;