import {categories, levels} from "./properties";

export const getExperienceEstimate = (surveyDiagnostics) => {
    return surveyDiagnostics?.detailedEstimates
        .find(estimate => estimate.scale === "EXPORT_EXPERIENCE")
        .scaleTotalEstimate;
}

export const getGrowthEstimate = (surveyDiagnostics) => {
    return surveyDiagnostics?.detailedEstimates
        .find(estimate => estimate.scale === "EXPORT_GROWTH")
        .scaleTotalEstimate;
}

export const diagnosticsToCategory = (surveyDiagnostics) => {
    const experienceEstimate = getExperienceEstimate(surveyDiagnostics)
    const growthEstimate = getGrowthEstimate(surveyDiagnostics)
    if (growthEstimate <= 50 && experienceEstimate <= 50) {
        return categories.BEGINNER;
    }
    if (growthEstimate > 50 && experienceEstimate <= 50) {
        return categories.PROMISING;
    }
    if (growthEstimate <= 50 && experienceEstimate > 50) {
        return categories.EXPERIENCED;
    }
    return categories.LEADER;
}

export const estimateToLevel = (estimate) => {
    if (estimate <= 30) {
        return levels.LOW
    }
    if (estimate > 30 && estimate <= 69) {
        return levels.MEDIUM
    }
    return levels.HIGH
}

export const getAverageByName = (name, average) => {
    switch (name) {
      case "EXPORT_EXPERIENCE": return Math.round(average?.EXPORT_EXPERIENCE)
      case "EXPORT_GROWTH": return Math.round(average?.EXPORT_GROWTH)
      default: return "0"
    }
  }
