import React from 'react';
import {ErrorMessage} from 'formik';

export default({formik, questionOptions, index}) => {

    const elementName = `answers[${index}].id`;

    return (
        <div>
                {questionOptions.map((o, oIndex) => (
                    <div key={"opt-div" + o.id}>
                        <label className="radio" key={"opt" + o.id}>
                            <span className="radio__input">
                                <input
                                    type="radio"
                                    name={elementName}
                                    value={questionOptions[oIndex].id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    defaultChecked={formik.values.answers[index]?.id===o.id.toString()}
                                />
                                <span className="radio__control"/>
                            </span>
                            <span className="control__label">{o.value}</span>
                        </label>
                        <ErrorMessage name={elementName} component="div" className="invalid-feedback"/>
                        </div>
                ))}
        </div>
    );
}