import React from 'react';
import QuestionOption from './QuestionOption';

export default({formik}) => {

  const {values} = formik;

  return (
    <div className="mt-3">
      Atsakymo variantai:
      <div className="p-2 bg-light">
        { values.questionOptions.map((radio, index) => (
          <QuestionOption formik={formik} index={index} key={index} />
        ))}
      </div>
    </div>
  );
}