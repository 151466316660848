import image from "./assets/Screenshot 2021-04-30 113541sm.png"

const ContactUs = () => {
    return (
        <div className="contact-us d-flex mt-5 mb-3 flex-wrap justify-content-center">
            <div className="col">
                <h1>MES PADEDAME VERSLUI AUGTI</h1>
                <p className="mt-3">
                    Verslumo ir eksporto plėtros agentūra Inovacijų agentūra padeda konkurencingiems verslams kurtis ir plėstis Lietuvoje, eksportuoti teikiant mokymų, konsultacijų ir verslo partnerių paieškos paslaugas.
                </p>
                <p>
                    Susisiekite su mumis: <a className="form__link" href="mailto:info@inovacijuagentura.lt">info@inovacijuagentura.lt</a>
                </p>
            </div>
            <div className="col d-flex justify-content-center contact-us__image-container">
                <img className="m-1" src={image} alt="Branda"/>
            </div>
        </div>
        )
}

export default ContactUs;