import HERO_IMAGE from "./assets/E-commerceLAB_1200x675-20.png"
import FormButton from "../../components/formik/elements/Button";

const Hero = ({onStart}) => {

    const backgroundStyle = {
        backgroundImage: `url(${HERO_IMAGE})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `right top`
    }

    return (
        <div className="hero p-5" style={backgroundStyle}>
            <div className="p-sm-5 hero__content">
                <h1>IŠSAMI JŪSŲ EKSPORTO PATIRTIES IR PROCESŲ ANALIZĖ PER 30 MINUČIŲ</h1>
                <h2>Įvertinkite, ar esate pasiruošę eksporto plėtrai</h2>
                <FormButton text="Pradėti Testą" onClick={onStart}/>
            </div>
        </div>
    )
}

export default Hero;