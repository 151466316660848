import {React} from 'react';
import {Button, Modal} from 'react-bootstrap';

const ListContentModal = ({show,
                       title,
                       bodyObject,
                       handleClose,
                       closeButtonTitle,
                       handleAction}) => {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyObject.map((row, index) => (
            <div onClick={() => handleAction(row)} key={index} style={{cursor: "pointer"}}>
              <strong>{index+1}. {row.name}</strong>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {closeButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ListContentModal;