import React from 'react';
import { Form } from 'react-bootstrap';
import {ErrorMessage} from 'formik';

export default({formik, questionOptions, index}) => {

  const elementName = `answers[${index}].id`;

  return (
    <div>
      <Form.Control as="select"
        name={elementName}
        className="form__dropdown"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.answers[index]?.id}
      >
        <option value=""></option>
        {questionOptions.map(o => (
          <option key={"opt" + o.id} value={o.id}>{o.value}</option>
        ))}
      </Form.Control>
      <ErrorMessage name={elementName} component="div" className="invalid-feedback" />
    </div>
  );
}