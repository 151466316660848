import EstimateIndicator from "./EstimateIndicator";

const RatingRow = ({rating}) => {
    const {ratingName: name, ratingTotalEstimate: estimate, ratingTotalInSector: average} = rating;
    const integerEstimate = Math.round(estimate)
    const integerAverage = Math.round(average)

    return (
        <div className="scale__row d-flex pl-4 pr-4 p-2 justify-content-between align-items-center">
            <h6>{name}</h6>
            <EstimateIndicator estimate={integerEstimate} average={integerAverage}/>
        </div>
    )
}

export default RatingRow;