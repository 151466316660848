import axios from 'axios'

const HTTP = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
})

HTTP.interceptors.request.use(config => {
    const endpoint = config.url;
    const isPublicEndpoint = new RegExp(/public/).test(endpoint);
    if (!isPublicEndpoint) {
        config.headers.authorization = localStorage.getItem('token');
    }
    return config;
})

HTTP.interceptors.response.use(response => response, (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        if (window.location.pathname !== "/login") {
            window.location.href = "/login"
        } else {
            function Error(message) {
                this.message = message;
                this.status = error.response.status;
            }

            throw new Error('Unauthorized');
        }
    } else {
        function Error(message) {
            this.message = message;
            this.status = error.response.status;
        }

        throw new Error(error.response.data.error);
    }
})

export {HTTP as default}