import Counter from "./Counter";
import {benefits} from "./properties/landingPageProperties";
import Benefit from "./Benefit";

const Benefits = () => {
    return (
        <div className="benefits mt-4 d-flex align-items-center">
            <div className="mr-2">
                <h1 className="mb-4 text-center">KOKIA NAUDA?</h1>
                <Benefit benefit={benefits.MATURITY_LEVEL}/>
                <Benefit benefit={benefits.RECOMMENDATIONS}/>
                <Benefit benefit={benefits.SERVICES_AND_TOOLS}/>
            </div>
            <Counter/>
        </div>
    )
}

export default Benefits;