const AnswersByRating = ({rating, answers}) => {

    const renderAnswer = ({questionId, questionName, answers}) => {
        return (
            <div key={questionId}>
                <p className="rating-answers__question mr-3 mt-3 pl-3"><strong>{questionName}</strong></p>
                <div className="d-flex">
                    <p>Pasirinktas atsakymas(-ai):</p>
                    <ul className="rating-answers__answer">
                        {answers.map(answer => <li key={`${questionId}/${answer.slice(0, 8)}`}>{answer}</li>)}
                    </ul>
                </div>

            </div>
        )

    }

    return (
        <div className="rating-answers p-3 mb-2">
            <span className="rating-answers__rating p-2 mb-2">Vertinimas: <strong>{rating}</strong></span>
            {answers.map(renderAnswer)}
        </div>
    )
}

export default AnswersByRating;