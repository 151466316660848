import "../../styles/footer.css"
import React from "react";

const Footer = () => {
    return (
            <footer className="footer d-flex justify-content-center">
                <span className="m-3">© „Inovacijų Agentūra“ | <a className="form__link" href="https://kc.inovacijuagentura.lt/turinys/privatumo-politika.html?lang=lt" target="_blank" rel="noreferrer">Privatumo Politika</a></span>
            </footer>
    )
}

export default Footer;