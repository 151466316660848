const Benefit = ({benefit: {icon, body}}) => {
    return (
        <div className="d-flex align-items-center mb-2">
            <img className="m-3" src={icon} alt="Branda"/>
            <div className="benefits__description ml-3">
                {body}
            </div>
        </div>
    )
}

export default Benefit;