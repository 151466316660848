import React from "react";

export function prepareAnswersArray(formValues) {
  let answersArray = [];
  const cleanArray = []
  formValues.answers.forEach((e, i) => {
    if (e && e.id !== null && e.id !== undefined) {
      cleanArray.push(e);
    }
  });

  cleanArray.forEach(e => {
      if (!Array.isArray(e.id)) {
        answersArray.push(
          {
            id: [e.id],
            value: e.value,
          }
        );
      }
      else {
        answersArray.push(e);
      }
    });
  return answersArray;
}

export const changeObjectNullFieldsToUndefined = (listOfObjects) => {
  listOfObjects.forEach(obj => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        obj[key] = undefined
      }
    })
  })
}

export const recreateQuestionShowsCheckboxIndexes = (questionShowsList) => {
  questionShowsList.forEach(triggeringQuestion => {
    const allOptions = triggeringQuestion.questionOptions;
    const allConditions = triggeringQuestion.questionShowConditions
    allConditions.forEach(condition => {
      // make a copy of array with default indexes
      const arrayCopyWithDefaultIndexes = [...condition.questionOptions];
      // clear original array
      const originalArray = condition.questionOptions;
      originalArray.length = 0;
      // populate empty array with elements, at explicitly defined indexes
      arrayCopyWithDefaultIndexes.forEach(triggeringOption => {
        const restoredIndex = allOptions.findIndex(option => option.id === triggeringOption.id)
        originalArray[restoredIndex] = triggeringOption;
      })
    })
  })
}

export const convertComparatorsToEnums = (questionShowsList) => {
  questionShowsList.forEach(triggeringQuestion => {
    if (triggeringQuestion.type === "SLIDER") {
      triggeringQuestion.questionShowConditions.forEach(condition => {
        switch (condition.compareSign) {
          case "=" : condition.compareSign = "EQUALS"; break;
          case ">" : condition.compareSign = "GREATER"; break;
          case ">=" : condition.compareSign = "GREATEROREQUALS"; break;
          case "<" : condition.compareSign = "LESS"; break;
          case "<=" : condition.compareSign = "LESSOREQUALS"; break;
          default : condition.compareSign = "";
        }
      })
    }
  })
}

export const convertEnumsToToComparators = (questionShowsList) => {
  questionShowsList.forEach(triggeringQuestion => {
    if (triggeringQuestion.type === "SLIDER") {
      triggeringQuestion.questionShowConditions.forEach(condition => {
        switch (condition.compareSign) {
          case "EQUALS" : condition.compareSign = "="; break;
          case "GREATER" : condition.compareSign = ">"; break;
          case "GREATEROREQUALS" : condition.compareSign = ">="; break;
          case "LESS" : condition.compareSign = "<"; break;
          case "LESSOREQUALS" : condition.compareSign = "<="; break;
          default : condition.compareSign = "";
        }
      })
    }
  })
}

export const stringifyIntervalNumbers = (questionOptions) => {
  questionOptions.forEach(opt=> {
    opt.recommendations.forEach(rec=>{
      if (rec.intervalNo) {
        rec.intervalNo = rec.intervalNo.toString()
      }
    })
  })
}


export const getTitleByScale = (scale) => {
  switch (scale) {
    case "EXPORT_EXPERIENCE": return "Eksporto patirtis"
    case "EXPORT_GROWTH": return "Pasirengimas eksporto augimui"
    default: return "Nežinoma skalė"
  }
}

export const getDetailsTitleByScale = (scale) => {
  switch (scale) {
    case "EXPORT_EXPERIENCE": return "Eksporto patirties vertinimo detalizacija"
    case "EXPORT_GROWTH": return "Pasirengimo eksporto augimui vertinimo detalizacija"
    default: return "Nežinoma skalė"
  }
}

export const getQuestionnaireIndexByName = (allQuestionnaires, questionnaireName) => {
  return allQuestionnaires
      .map(questionnaire => questionnaire.name)
      .indexOf(questionnaireName);
}
