import FormHeading from "../../components/formik/elements/FormHeading";
import React from "react";

const SurveyAttemptAnonymous = () => {
    return (
        <div className="container-fluid col-xl-7 mb-4 pt-4">
            <FormHeading text="Pageidaujate atlikti savo įmonės eksporto diagnostikos testą?"/>
            <p className="mt-5">
                Dėl prieigos susisiekite su mumis el. paštu <a href="mailto:konsultacijos@inovacijuagentura.lt" className="form__link">konsultacijos@inovacijuagentura.lt</a>.
            </p>
        </div>
        )
}

export default SurveyAttemptAnonymous;