import FormHeading from "../../components/formik/elements/FormHeading";
import React, {useEffect, useState} from "react";
import * as statisticsApi from "../../api/statisticsApi";
import ListQuestions from './list/ListQuestions'

const QuestionsAll = () => {
    const [dataQuestions, setDataQuestions] = useState([])

    const fetchQuestionAll = () => {
        statisticsApi.fetchQuestionAll()
            .then(({ data }) => setDataQuestions(data))
    }
  
    const calcPercent = (count, percent) => {
      return `${Math.round((percent / count) * 100)}%`
    }
  
    useEffect(() => {
      fetchQuestionAll()
    }, [])

  return (
      <div className="container-fluid w-90">
          <FormHeading text="Klausimų statistika"/>
          <ListQuestions calcPercent={calcPercent} data={dataQuestions} />
      </div>
  );
}

export default QuestionsAll;
