import {Modal} from "react-bootstrap";
import FormButton from "../../../components/formik/elements/Button";

const DeleteConfirm = ({showConfirmationModal, handleClose, handleConfirm, companyToInvite, isLoading}) => {
    const {companyName, email, inviteCount} = companyToInvite;

    const warn = {
        color: "var(--dark-blue",
        fontWeight: "bold"
    }
    return (
        <Modal className="modal" show={showConfirmationModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Patvirtinkite veiksmą</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <p>
                        <span className="mb-3">Pakvietimas bus išsiųstas įmonei&nbsp;</span>
                        <span style={warn}>{companyName}&nbsp;</span>
                    </p>
                    <p>
                        <span>el. paštu:&nbsp;</span>
                        <span style={warn}>{email}</span>
                    </p>
                    <br/>
                    {inviteCount === 0 && <i>Įmonei pakvietimas dar nebuvo išsiųstas.</i>}
                    {inviteCount > 0 && <i>Įmonei jau buvo siųstas(-i) <span style={warn}>{inviteCount}</span> pakvietimas(-ai) dalyvauti.</i>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <FormButton text="Atšaukti" onClick={handleClose} isSwitched mr/>
                <FormButton text={isLoading ? "Vykdoma..." : "Patvirtinti"} onClick={handleConfirm}
                            disabled={isLoading}/>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirm