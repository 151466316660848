import React from "react";

export const sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}) => (
    <div className="btn-group" role="group">
        {
            options.map((option) => {
                const isSelect = currSizePerPage === `${option.page}`;
                return (
                    <button key={option.text} type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-light'}`}
                    >
                        {option.text}
                    </button>
                );
            })
        }
    </div>
);