import React from "react";
import TooltipComponent from "../../tooltip/TooltipComponent";
import info from "./assets/info.svg"


const QuestionHeading = ({text, hint}) =>
    (
        <h3 className="question__heading mt-1 mb-2">
            {`${text} `} {
            hint !== "" &&
            <TooltipComponent
                component={
                        <img className="i-circle" src={info} alt="info"/>
                }
                tooltipText={hint}
                placement="right"
                showTooltip={true}/>}
        </h3>
    )

export default QuestionHeading;