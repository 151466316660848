import {estimateToColor, estimateToLevel, getAverageByName} from "./categories/utils";

const EstimateIndicator = ({estimate, lg, sm, hasLevelName, average, sectorAverage, name, noSector}) => {

    const level = estimateToLevel(estimate)

    const largeBubble = `30px`
    const mediumBubble = `15px`
    const smallBubble = `12px`
    const largeFont = `24px`
    const mediumFont = `14px`
    const smallFont = `12px`

    const style = {
        bubble: {
            width: (lg && largeBubble) || (sm && smallBubble) || mediumBubble,
            height: (lg && largeBubble) || (sm && smallBubble) || mediumBubble,
            borderRadius: `50%`,
            backgroundColor: level.color,
        },
        estimate: {
            fontSize: (lg && largeFont) || (sm && smallFont) || mediumFont,
            fontWeight: `bold`,
        },
        maxEstimate: {
            fontSize: sm ? smallFont : mediumFont,
            fontWeight: `normal`,
        },
        levelName: {
            color: level.color,
            fontSize: (lg && largeFont) || (sm && smallFont) || mediumFont,
        }
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center estimate-indicator  justify-content-end">
                <div style={style.bubble} className={sm ? "mt-2 mr-2 mb-2" : "m-1"}/>
                {hasLevelName && <span style={style.levelName}>{level.name}</span>}
                <span style={style.estimate} className={sm ? "ml-2" : "m-1"}>{estimate}</span>
                <span style={style.maxEstimate} className={sm ? "m-2" : "m-1"}>/</span>
                <span style={style.maxEstimate} className={sm ? "m-2" : "m-1"}>100</span>
            </div>
            <span style={style.maxEstimate} className={sm ? "m-2" : "m-1"}>
                {!noSector && (
                    <>Sektoriaus vidurkis {average ? average : getAverageByName(name, sectorAverage)} / 100</>
                )}
            </span>
        </div>
        )
}

export default EstimateIndicator;