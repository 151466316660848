import React from 'react';
import { Form, Col } from 'react-bootstrap';
import {ErrorMessage} from 'formik';
import QuestionOptionRecommendation from "./questionOptionRecommendation/QuestionOptionRecommendation";
import FormButton from "../../components/formik/elements/Button";

export default ({formik, index}) => {

  const removeQuestionOption = () => {
    const questionOptions = [...formik.values.questionOptions];
    questionOptions.splice(index,1)
    formik.setValues({
      ...formik.values,
      questionOptions
    });
  }

  const recommendationsLabel = formik.values.type === 'SLIDER'
      ? "Atsakymo intervalas sąlygoja rekomendaciją"
      : "Šio varianto pasirinkimas sąlygoja rekomendaciją";

  return (
    <>
    <Form.Row className="m-3" key={index}>
      { formik.values.type !== 'SLIDER' &&
        (<Form.Group as={Col} controlId="valueGroup">
          <Form.Label>Reikšmė</Form.Label>
          <Form.Control
            type="text"
            name={`questionOptions[${index}].value`}
            value={formik.values.questionOptions[index].value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage name={`questionOptions[${index}].value`} component="div" className="invalid-feedback" />
        </Form.Group>
      )}

      { formik.values.type !== 'SLIDER' &&
        (<Form.Group as={Col} controlId="weightGroup">
          <Form.Label>Svoris</Form.Label>
          <Form.Control
            type="text"
            name={`questionOptions[${index}].weight`}
            value={formik.values.questionOptions[index].weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage name={`questionOptions[${index}].weight`} component="div" className="invalid-feedback" />
        </Form.Group>
      )}
      { formik.values.type !== 'SLIDER' && formik.values.type !== 'INPUT' &&
      (
          <Form.Group as={Col} controlId="removeGroup"  className="d-flex">
            <div className="d-flex align-items-end">
              <FormButton
                  isSmall
                  text="pašalinti"
                  type="button"
                  onClick={removeQuestionOption}/>
            </div>
          </Form.Group>
      )}

      { formik.values.type === 'SLIDER' &&
        (<Form.Group as={Col} controlId="minGroup">
          <Form.Label>Min.</Form.Label>
          <Form.Control
            type="text"
            name={`questionOptions[${index}].min`}
            value={formik.values.questionOptions[index].min}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage name={`questionOptions[${index}].min`} component="div" className="invalid-feedback" />
              <Form.Label className="mt-3">Min. antraštė</Form.Label>
              <Form.Control
                  type="text"
                  name={`questionOptions[${index}].minTitle`}
                  value={formik.values.questionOptions[index].minTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
              />
              <ErrorMessage name={`questionOptions[${index}].minTitle`} component="div" className="invalid-feedback" />
        </Form.Group>
      )}

      { formik.values.type === 'SLIDER' &&
      (<Form.Group as={Col} controlId="stepGroup" column="sm" sm={2}>
            <Form.Label>Žingsnis</Form.Label>
            <Form.Control
                type="text"
                name={`questionOptions[${index}].step`}
                value={formik.values.questionOptions[index].step}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

            />
            <ErrorMessage name={`questionOptions[${index}].step`} component="div" className="invalid-feedback" />
            <Form.Label className="mt-3">Svoris</Form.Label>
            <Form.Control
                type="text"
                name={`questionOptions[${index}].weight`}
                value={formik.values.questionOptions[index].weight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <ErrorMessage name={`questionOptions[${index}].weight`} component="div" className="invalid-feedback" />
          </Form.Group>
      )}

      { formik.values.type === 'SLIDER' &&
      (<Form.Group as={Col} controlId="unitsGroup" column="sm" sm={2}>
            <Form.Label>Mato vienetai</Form.Label>
            <Form.Control
                type="text"
                name={`questionOptions[${index}].units`}
                value={formik.values.questionOptions[index].units}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

            />
            <ErrorMessage name={`questionOptions[${index}].units`} component="div" className="invalid-feedback" />
          </Form.Group>
      )}

      { formik.values.type === 'SLIDER' &&
        ( <Form.Group as={Col} controlId="maxGroup">
          <Form.Label>Maks.</Form.Label>
          <Form.Control
            type="text"
            name={`questionOptions[${index}].max`}
            value={formik.values.questionOptions[index].max}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessage name={`questionOptions[${index}].max`} component="div" className="invalid-feedback" />
              <Form.Label className="mt-3">Maks. antraštė</Form.Label>
              <Form.Control
                  type="text"
                  name={`questionOptions[${index}].maxTitle`}
                  value={formik.values.questionOptions[index].maxTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
              />
              <ErrorMessage name={`questionOptions[${index}].maxTitle`} component="div" className="invalid-feedback" />
        </Form.Group>
      )}

    </Form.Row>
      <Form.Row className="m-3 d-flex flex-column" key={"cbr"+ index}>
        {formik.values.type !== 'INPUT' &&
            <>
              <Form.Check
                  label={recommendationsLabel}
                  type="checkbox"
                  name={`questionOptions[${index}].hasRecommendations`}
                  value={formik.values.questionOptions[index].hasRecommendations}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="ml-3"
                  checked={formik.values.questionOptions[index].hasRecommendations}
        />
        </>
        }
        <QuestionOptionRecommendation formik={formik} optionIndex={index}/>
      </Form.Row>
  </>
  );
}