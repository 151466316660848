import FormHeading from "../../components/formik/elements/FormHeading";
import React, {useEffect, useState} from "react";
import * as statisticsApi from "../../api/statisticsApi";
import Select from "../../components/formik/elements/Select";
import ListGroup from "../../components/formik/elements/ListGroup";
import CompaniesTable from "./table/CompaniesTable";
import ErrorAlert from "../error/ErrorAlert";

const StatisticsAverage = () => {
    const [listType, setListType] = useState([])
    const [ListAverage, setListAverage] = useState([])
    const [sectorIndx, setSectorIndx] = useState(0)
    const [dataTable, setDataTable] = useState([])
    const [attendeePage, setAttendeePage] = useState({
        attendees: [],
        activePage: 1,
        totalPages: null,
        itemsCountPerPage: null,
        totalItemsCount: null
    })
    const [error, setError] = useState(null)

    const fetchAttendeePage = () => {
        statisticsApi.fetchSectorlist()
            .then(({ data }) => setListType(Object.values(data)))
            .catch((error) => setError(error))
    }

    const fetchSectorType = () => {
        statisticsApi.fetchSectorType(sectorIndx)
            .then(({ data }) => setListAverage(data))
            .catch((error) => setError(error))
    }

    const fetchSector = () => {
        statisticsApi.fetchSector(sectorIndx)
            .then(({ data }) => {
                const test = data?.map(i => {
                    let newRatingResults = []
                    i?.surveyDiagnostics?.detailedEstimates?.map(k => {
                        k.ratingResults?.map(fff => {
                            newRatingResults.push({...fff, ratingTotalEstimate: Math.round(fff?.ratingTotalEstimate)})
                        })

                    })
                    return {
                        ...i, ...newRatingResults
                    }
                })
                setDataTable(test)
            })
    }

    const onChange = e => setSectorIndx(+e.target.value)

    useEffect(() => {
        fetchAttendeePage()
    }, [])

    useEffect(() => {
        fetchSectorType()
        fetchSector()
    }, [sectorIndx])

    const titleTable = [
        'Įmonė', 'Sektorius', 'Bazinės įmonės pasirengimas', 'Tikslinių rinkų ir segmentų pažinimas', 'Produktų / paslaugų paruošimas eksportui', 'Rinkodara ir viešieji ryšiai', 'Eksporto pardavimų proceso valdymas ir įgūdžiai', 'Pasiryžimas plėsti eksportą ir veiksmų planavimas', 'Eksporto kanalų diversifikavimas', 'Eksporto dalis apyvartoje', 'Eksporto istorija', 'Eksporto geografija', 'Eksporto apimtys',
    ]

    return (
        <div className="container-fluid w-90">
            {error && <ErrorAlert error={error}/>}
            {!error &&
            <>
                <FormHeading text="Įmonių statistika"/>
                <Select items={listType} onChange={onChange} />
                <ListGroup items={ListAverage} />
                {attendeePage.attendees.length !== 0
                    ? <p>Registruotų įmonių sąrašas tuščias.</p>
                    : <CompaniesTable
                        data={dataTable}
                        page={attendeePage.activePage}
                        sizePerPage={attendeePage.itemsCountPerPage}
                        totalSize={attendeePage.totalItemsCount}
                        titleTable={titleTable}
                    />
                }
            </>
            }
        </div>
    );
}

export default StatisticsAverage;