import {diagnosticsToCategory} from "./categories/utils";
import {categories} from "./categories/properties";

const DiagnosticCategories = ({surveyDiagnostics}) => {

    const activeCategory = diagnosticsToCategory(surveyDiagnostics)
    const inactiveColor = `var(--white-grey-shade-2)`

    const getColor = (category) => {
        if (category !== activeCategory) {
            return inactiveColor
        } else {
            return category.color
        }
    }

    const style = {
        category: (category) => ({
            width: `100%`,
            marginTop: `20px`,
            marginLeft: category.grade === 1 ? 0 : `5px`,
            marginRight: category.grade === 4 ? 0 : `5px`,
            borderBottom: `5px solid ${getColor(category)}`
        }),
        title: (category) => ({
            color: getColor(category),
            fontWeight: `normal`,
            textAlign: `center`,
            margin: 0,
        }),
    }

    const renderCategory = (category) => {
        return (
            <div key={`cat${category.grade}`} style={style.category(category)} className="category-name">
                <h3 style={style.title(category)}>{category.title}</h3>
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-between">
            {Object.values(categories).map(renderCategory)}
        </div>
    )
}

export default DiagnosticCategories;