import React, {useEffect, useState} from "react";
import {Alert, Spinner, Table, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import ratingApi from "../../api/ratingApi";
import FormButton from "../../components/formik/elements/Button";
import FormHeading from "../../components/formik/elements/FormHeading";

export default () => {
    const [ratings, setRatings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () =>
        ratingApi
            .fetchRatings()
            .then((response) => setRatings(response.data))
            .finally(() => setIsLoading(false));

    const onDelete = (id) => {
        ratingApi
            .deleteRating(id)
            .then((response) => {
                fetchData();
                setError(null)
            })
            .catch((err) => {
                setError("Vertinimas negali būti ištrintas nes yra naudojamas kituose klausimynuose.");
            });
    };

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Vertinimų sąrašas"/>
            {error && <Alert variant="danger">{error}</Alert>}
            <Link to="/cms/rating/new">
                <FormButton text="Kurti naują vertinimą"/>
            </Link>
            {isLoading && (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )}
            {ratings.length > 0 && (
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>Vertinimas</th>
                        <th>Svoris</th>
                        <th colSpan={2}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {ratings.map((t) => (
                        <tr key={t.id}>
                            <td className="w-100">{t.name}</td>
                            <td>{t.weight}</td>
                            <td>
                                <Link to={`/cms/rating/${t.id}/edit`}>
                                    <FormButton isForTable text="Redaguoti"/>
                                </Link>
                            </td>
                            <td>
                                <FormButton isForTable text="Ištrinti" onClick={() => onDelete(t.id)}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
            {ratings.length === 0 && (
                <Alert variant="info">
                    Neturite sukurtų vertinimų. Prašome sukurti bent vieną vertinimą.
                </Alert>
            )}
        </div>
    );
};
