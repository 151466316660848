import {React} from 'react';

const ProgressBar = ({ completed }) => {

  const fillerVariable = {
    width: `${completed}%`
  }

  return (
    <div>
      <div className="progress__outer-label">Progresas - {completed}%</div>
      <div className="progress__container">
        <div className="progress__filler" style={fillerVariable}>
          <span className="progress__inner-label">{/*completed > 0 ? `Progresas - ${completed}%` : ''*/}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;