import React from "react";

const CategoryDescription = ({category}) => {
    return (
        <div className="p-3 text-center diagram__description d-flex align-items-center">
            {category.description}
        </div>
        )
}

export default CategoryDescription;