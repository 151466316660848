import {Route, Redirect, useLocation} from "react-router-dom";
import _ from 'lodash'

const PrivateRoute = ({ children, roles, ...props }) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const location = useLocation()
    const authorized = roles ? !!_.intersection(user?.roles, roles).length : !!user

    return (
           <Route {...props}>
            {
                authorized ? children : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {
                                from: location
                            }
                        }}
                    />
                )
            }
        </Route>
    )
}

export default PrivateRoute;