import React from 'react';
import { Form } from 'react-bootstrap';
import {ErrorMessage} from 'formik';
import { getIn } from 'formik';

export default({formik, questionOptions, index}) => {

  const elementName = `answers[${index}].id`;
  const elementValue = `answers[${index}].value`;

  return (
    <div>
      <Form.Label>{questionOptions[0].label}</Form.Label>
      <Form.Control
          type="text"
          name={elementName}
          onChange={e => {
              let answers = formik.values.answers;
              answers[index] = {id: questionOptions[0].id,
                                value: e.target.value};
              formik.setValues({
                ...formik.values,
                answers
              });
            }
          }
          onBlur={formik.handleBlur}
          value={getIn(formik.values, elementValue)}
          isInvalid={!!getIn(formik.errors, elementName)}
      />
      <ErrorMessage name={elementName} component="div" className="invalid-feedback" />
    </div>
  );
}