import React, {useEffect, useState} from "react";
import {Alert, Spinner, Table} from "react-bootstrap";
import {NavLink, Link} from "react-router-dom";
import surveyApi from "../../api/surveyApi";
import questionnaireApi from "../../api/questionnaireApi";
import FormHeading from "../../components/formik/elements/FormHeading";
import FormButton from "../../components/formik/elements/Button"

export default ({match}) => {
    const [questionnaires, setQuestionnaires] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchSurveyQuestionnaires();
    }, []);

    const fetchSurveyQuestionnaires = () => {
        surveyApi.fetchSurveyQuestionnaires(match.params.id)
            .then(response => setQuestionnaires(response.data))
            .finally(() => setIsLoading(false));
    }

    return (
        <div className="container-fluid col-xl-7">
            <FormHeading text="Temų sąrašas"/>
            <div className="d-flex justify-content-between">
                <Link to="/cms/questionnaire/new"><FormButton text="Kurti naują temą"/></Link>
                {/*{!isLoading && <Link to={`/cms/${match.params.id}/attempt/new`}><FormButton text="Bandyti apklausą" /></Link>}*/}
            </div>
            {isLoading &&
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>}

            {!isLoading && !questionnaires.length ?
                <Alert variant="info">
                    Neturite sukurtų temų. Prašome sukurti bent vieną.
                </Alert> :
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>Temos pavadinimas</th>
                        <th colSpan={2}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isLoading && questionnaires.map(({id, name}) => (
                        <tr key={id}>
                            <td className="w-100" style={{verticalAlign:"middle"}}>{name}</td>
                            <td>
                                <Link to={`${match.params.id}/questionnaire/${id}`}>
                                    <FormButton isForTable text="Klausimai"/>
                                </Link>
                            </td>
                            <td>
                                <Link to={`/questionnaire/${id}/edit`}>
                                    <FormButton isForTable text="Pervadinti"/>
                                </Link>
                            </td>
                          <td>
                          <FormButton
                              isForTable
                              text="Ištrinti"
                              type="button"
                              onClick={() => {questionnaireApi.deleteQuestionnaire(id)}}
                          />
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
        </div>
    )
}