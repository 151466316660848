import React from 'react';
import {FormGroup} from 'react-bootstrap';
import Field from '../../components/formik/elements/Field';
import '../../styles/forms.css';
import Checkbox from "../../components/formik/elements/Checkbox";
import FormHeading from "../../components/formik/elements/FormHeading";

export default ({formik}) => {
    return (
        <div className="progress__content-offset">
            <FormHeading text="Užpildykite kontaktinius duomenis"/>
            <FormGroup>
                <Field label="Įmonės pavadinimas"
                       type="text"
                       name="attendee.companyName"
                       disabled={!!formik.values.attendee.attendeeCode}
                       formik={formik}
                       placeHolder="Įvesti tekstą"
                       isRequired
                />
                <Field label="Įmonės tinklalapis"
                       type="text"
                       name="attendee.companyWebsite"
                       formik={formik}
                       placeHolder="Įvesti tekstą"
                />
                <Field label="Jūsų vardas, pavardė"
                       type="text"
                       name="attendee.attendeeName"
                       formik={formik}
                       placeHolder="Įvesti tekstą"
                       isRequired
                />
                <Field label="Jūsų el. paštas"
                       type="text"
                       name="attendee.email"
                       disabled={!!formik.values.attendee.attendeeCode}
                       formik={formik}
                       placeHolder="Įvesti tekstą"
                       isRequired
                />
                <Field label="Jūsų tel. numeris"
                       type="text"
                       name="attendee.phoneNo"
                       formik={formik}
                       placeHolder="Įvesti tekstą"
                />
                <div className="mt-4">
                    <Checkbox
                        name="attendee.policyAgree"
                        text="Sutinku su „Inovacijų Agentūra“"
                        linkText="privatumo politika"
                        linkUrl="https://kc.inovacijuagentura.lt/turinys/privatumo-politika.html?lang=lt"
                        isRequired
                    />
                    <Checkbox
                        name="attendee.recommendationsAgree"
                        text="Sutinku gauti su eksporto skatinimu susijusias rekomendacijas pagal testo rezultatus"
                    />
                </div>
            </FormGroup>
        </div>
    );
}