import {React} from 'react';
import {Button, Modal} from 'react-bootstrap';

const TwoButtonModal = ({show,
                         title,
                         body,
                         handleClose,
                         closeButtonTitle,
                         handleAction,
                         actionButtonTitle}) => {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {closeButtonTitle}
          </Button>
          <Button variant="primary" onClick={handleAction}>
            {actionButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TwoButtonModal;