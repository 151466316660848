import HTTP from './index';
import {prepareAnswersArray} from './utils';

export default {

    fetchQuestionnaireQuestions(surveyId, questionnaireId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/${questionnaireId}/content`)
    },

    addQuestion(formValues, surveyId, questionnaireId) {
        const values = {
          ...formValues
        };
        return HTTP.post(`/survey/${surveyId}/questionnaire/${questionnaireId}/question`, values)
    },

    fetchQuestionFields() {
        return HTTP.get(`/survey/1/questionnaire/1/question/form`)
    },

    fetchQuestionForEdit(surveyId, questionnaireId, questionId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/${questionnaireId}/question/edit/${questionId}`);
    },

    updateQuestion(formValues, surveyId, questionnaireId, questionId) {
        const values = {
            ...formValues
        };
        return HTTP.put(`/survey/${surveyId}/questionnaire/${questionnaireId}/question/edit/${questionId}`, values)
    },

    deleteQuestionnaireQuestion(surveyId, questionnaireId, questionId) {
        return HTTP.delete(`/survey/${surveyId}/questionnaire/${questionnaireId}/question/delete/${questionId}`)
    },

    fetchSurveyFirstQuestion(surveyId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/0/question/public/first`)
    },

    fetchSurveyQuestionsCount(surveyId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/0/question/public/count`)
    },

    fetchSurveyQuestions(surveyId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/0/question/public/survey-all`)
    },

    fetchSurveyCertainQuestion(surveyId, questionId) {
        return HTTP.get(`/survey/${surveyId}/questionnaire/0/question/public/${questionId}`)
    },

    fetchSurveyNextQuestion(surveyId, formValues) {
      let answersArray = prepareAnswersArray(formValues);
      const values = {
        currentAnswer: answersArray[answersArray.length-1],
        answers: answersArray
      };
      return HTTP.post(`/survey/{surveyId}/attempt/public/next`, values)
    },
}